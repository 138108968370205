import React from 'react'
import image1 from '../assets/sk_sir.jpg'
import '../../src/About/Aboutus.css'
import { GoArrowRight } from "react-icons/go";
import { FaYoutube } from "react-icons/fa";
import Header from '../Header'
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
const Aboutus = () => {
    const Navigate = useNavigate();
    return (
        <div>
            <div style={{ width: "100%", overflow: "hidden", display: "flex", flexDirection: "column" }}>
                {/* Navbar */}
                <div style={{ flex: "0 0 " }}>
                    <Header />
                </div>

                {/* Main Content */}
                <div style={{ flex: "1 1 auto", overflowY: "auto" }}>
                    <div className='p-2 container' style={{ marginTop: "40px" }}>
                        <div className='row '>
                            <div className='col-12  p-5'>

                                <div className='container'>
                                    <div className='row mt-5'>
                                        <div className='col-12 '>
                                            <h1 className='head ms-5'>Meet  the  Backbone  of<br></br> Brainbucks</h1>
                                        </div>
                                    </div>

                                    <div className='row mt-3  d-flex justify-content-center'>
                                        <div className='col-lg-5 col-md-12 col-sm-12 '>
                                            <div>
                                                <img src={image1} style={{ width: "100%", height: "100%" }}></img>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12 col-sm-12 d-flex flex-column  justify-content-center '>
                                            <div style={{ lineHeight: "26px" }}>
                                                <p className='para'>It has been a wonderful journey, the users of our Platform have kept trust on us, since our inception in year 2022, we all have came a long way from a small startup to a magnificent Ed Tech Giant, since this global industry ows it’s every penny to it’s users, we are extremely delightful for the users who trusts us. And especially we kudos to the users who are so keen into building their careers in such a passionate way.</p>

                                                <p className='para'>We hope that every aspirant gets the value of his/her time which they have invested in the preparation of their dream job.

                                                </p>
                                            </div>
                                            <h3 className='name'>Santosh Kumar Yadav</h3>
                                            <h6 className='name'>Founder CEO</h6>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-lg-5'></div>
                                        <div className='col-lg-6 '>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='row d-flex justify-content-center  gap-2'>
                                                        <div className='col-lg-5 col-md-12 col-sm-12 '>
                                                            <div className=''>
                                                                <button type='button' className='contributor'  onClick={() => {
                                                                    Navigate('/team')}}>View all Contributors</button>
                                                                <GoArrowRight className='arrow' />
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-5 col-md-12 col-sm-12 '>
                                                            <a href="https://www.youtube.com/@inspirationbysantosh" target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                <div className='story d-flex gap-2 justify-content-center' style={{ cursor: "pointer" }}>
                                                                    <div className='mt-1'>
                                                                        <span style={{ fontWeight: "600" }}>Watch Success Stories</span>
                                                                    </div>
                                                                    <div className='d-flex align-items-center'>
                                                                        <FaYoutube style={{ fontSize: "25px", color: "red" }} />
                                                                    </div>
                                                                </div>
                                                            </a>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    )
}

export default Aboutus
