import React from 'react';
import Home from './Home/Home';
import { Route, Routes } from 'react-router-dom';
import Aboutus from './About/Aboutus';
import Team from './Team/Team';
import Update from './update/Update';
import ShowBlog from './ShowBlog/ShowBlog';
import Blog from './blogs/Blog';
import ViewAllBlogs from './blogs/ViewAllBlogs';
import BlogCategory from './blogs/BlogCategory';
import PrivacyPolicy from './SubFooter/PrivacyPolicy';
import Regulation from './SubFooter/Regulation';
import TermsandConditions from './SubFooter/TermsandConditions';
import Refund from './SubFooter/Refund';
import DeleteAccount from './DeleteAccount/DeleteAcc';


function App() {
  return (
    <div>
      {/*  */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<Aboutus />} />
        <Route path='/about' element={<Aboutus />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/view/all/blog/:id' element={<ViewAllBlogs />} />
        <Route path='/view/show/blog/:id' element={<ShowBlog />} />
        <Route path='/view/blog/category/:id' element={<BlogCategory />} />
        <Route path='/team' element={<Team />} />
        <Route path='/update' element={<Update />} />
        <Route path='/privacy/policy' element={<PrivacyPolicy />} />
        <Route path='/regulation' element={<Regulation />} />
        <Route path='/terms/condition' element={<TermsandConditions />} />
        <Route path='/refund/cancellation/policy' element={<Refund />} />
        <Route path='/deleteaccount' element={<DeleteAccount />} />
      </Routes>
    </div>
  );
}
export default App;
