import React, { useEffect, useState } from 'react'
import { IMAGE_URL, PRERECMICRO } from '../config/url';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import Header from '../Header';
import logo from '../assets/logo.png';
import { useNavigate, useParams } from "react-router-dom";
import Footer from '../Footer';

const BlogCategory = () => {
    const Navigate = useNavigate();
    const [catblog, setcatblog] = useState([])
    const { id } = useParams()


    const view_blog_category = async (page, limit) => {
        try {
            const requestOptions = {
                method: "GET",
                redirect: "follow"
            };

            const response = await fetch(`${PRERECMICRO}/learning/page/get/blog/by/category?categoryId=${id}&page=${page}&limit=${limit}`, requestOptions)
            const result = await response.json()
            if (result.status == 1) {
                setcatblog(result.blogs)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        view_blog_category(1, 10)
    }, [])

    return (
        <div>
        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
          {/* Navbar */}
          <div style={{ height: "7vh" }}>
            <Header />
          </div>
      
          {/* Main Content */}
          <div className='border' style={{}}>
            <div className='container'>
              <div className='row mt-5'>
                <div className='col-12'>
      
                  <div className='container mt-5'>
                    <div className='row d-flex justify-content-around'>
      
                      {catblog.length > 0 ? (
                        catblog?.map((res, item) => {
                          return (
                            <div key={item} className='col-lg-4 col-sm-12'>
                              <div className="card" style={{ width: "100%", marginBottom: "20px" }}>
                                <div
                                  style={{
                                    width: "100%",
                                    border: "1px solid #808080",
                                    height: "200px",
                                  }}
                                >
                                  <img
                                    src={IMAGE_URL + res.banner}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                                <div className="card-body d-flex flex-column">
                                  <h6>{res.title}</h6>
                                  <div className='mt-4 d-flex align-items-center justify-content-between'>
                                    <div className='d-flex'>
                                      <div className='d-flex gap-2'>
                                        <img src={logo} style={{ height: "3vh" }} />
                                        <span className='new-txt'>Brainbucks</span>
                                      </div>
                                    </div>
                                    <div className='d-flex align-items-center gap-2'>
                                      <MdOutlineCalendarMonth className='txx' style={{ fontSize: "18px" }} />
                                      <span className='txx'>{res.scheduleDateTime}</span>
                                    </div>
                                  </div>
                                  <button type='button' className='contributor' onClick={() => {
                                    Navigate(`/view/show/blog/${res._id}`);
                                  }}> Read as Somewhere</button>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                          <h3 style={{ textAlign: 'center' }}>No Blog data available</h3>
                        </div>
                      )}
      
                    </div>
                  </div>
      
                </div>
              </div>
            </div>
          </div>
      
        </div>
        <Footer />
      </div>
      
    )
}

export default BlogCategory
