import React from 'react'
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const Regulation = () => {
    const navigate = useNavigate();
    const navigateBack = () => {
        navigate(-1);
    };
    return (
        <div>
            <div className='container-fluid'>
                <div className='row pt-4 ps-2'>
                    <div className='col-12'>
                        <div
                            style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                border: "1px solid black",
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "white",
                            }}
                            onClick={navigateBack}
                        >
                            <BiLeftArrowAlt size="cssUnit" style={{ color: "black" }} />
                        </div>


                        <div className='mt-4 text-center'>
                            <u style={{ fontWeight: "bold", fontSize: "20px" }}>Participation Rules & Policies</u>

                        </div>




                        <div className='mt-4'>
                            <h6>Brainbucks Rules and Terms :</h6>
                            <span style={{ fontSize: "14px", lineHeight: "1px",  }}>
                                1. This is “Not a gambling app” but contains financial risks.<br />

                                2. Rewards in regular contests and quizzes are based upon the number of participants that join the contests.<br />

                                3. There are two types of contests: 1. Free to Join and, 2. Paid (Includes an Entry Fee)<br />

                                4. There are also some live contests to join and participate in in real-time.<br />

                                5. For the Paid Contests, the entry fee varies from contest to contest.<br />

                                6. Rules for selecting a winner of the quiz and distributing the prize pool:<br/>

                                i. Total of top 20% participants are considered as Winning group of a quiz, who receives the cash prize at the end of the quiz. Formula for calculating the Winners is:<br/>

                                r = ( 20 * TP)/100<br/>
                                r = Number of Winners<br/>
                                TP = Total number of participants participating in the quiz.<br/>

                                ii. The winners are determined by the accuracy of the answers they give to the questions asked in the quiz, accuracy is determined by the following formula:<br/>

                                A = (( Nc )/ Nq)*100<br/>
                                Where,<br/>
                                A = Accuracy<br/>
                                Nc = Total number of questions answered correctly<br/>
                                Nq = Total number of questions asked.<br/>

                                iii. For example: the total amount of players participating in a quiz are total 100, and the entry fees for each participant is 10 BB Coins, then the total prize pool sums up to 800 BB Coins, therefore this prize pool will be distributed among top 20% players that is to top 20 players in this case.<br/>

                                iv. Distribution of the prize among winners:<br/>

                                a. The achiever with the highest amount of accuracy gets the biggest chunk of prize pool.<br/>

                                b. The distribution of prize pool is determined by the following formula:<br/>

                                pp = [((r-R) + r)*PP]/Tp<br/>
                                Where,<br/>
                                pp = Prize to be received by winner.<br/>
                                R = Rank of Winner<br/>
                                r = Total number of winners<br/>
                                PP = Total amount of prize pool<br/>
                                Tp = Total Participants of the quiz<br/>

                                c. For Example : If in the previous scenario the total number of Winners ( r ) are 20 and the amount of prize pool ( PP ) is 800 BB Coins and the Total Participants (Tp) are 100, then according to the above formula, winner with the rank 1, will get a Prize ( pp ) of 312 BB Coins and the winner with rank 2 will get a prize of 304 BB Coins and so on.<br/>

                                v. In case of Tie:<br/>

                                a. If the accuracy of 2 players comes same with the above mentioned formula, then the player completing quiz With lower Average Time Per Question (T) is selected on a higher rank, formula of calculating T is as follows :<br/>

                                T = (t/q)<br/>
                                Where,<br/>
                                T = Average Time per Question<br/>
                                t = Total time taken to complete the quiz in seconds<br/>
                                q = Total number of questions asked in the quiz<br/>

                                b. For example : If accuracy of 2 participants , let the name of participants be “A” and “B” came same in a quiz containing total 10 Questions but the total time taken by “A” to complete the quiz ( t ) was 20 Seconds and the total time taken by “B” to complete the quiz ( t ) was 25 Seconds, then Participant “A’s” Average Time Per Question ( T ) is 2 Seconds , according to the above mentioned formula, whereas Participant “B” is having Average Time per Question ( T ) is 2.5 Seconds, therefore according to the algorithm Participant A will be assigned a higher Rank than Participant B..<br/>

                                7. For Paid contest a total of 20% commision will be charged by Brain Bucks, for instance if the total number of participants participating in a quiz contest is 100 and the participation fees is INR 100 per Participant, which will result into a total prize pool of INR 10,000, brain bucks will deduct 20% i.e INR 2,000 from the principal amount, which will lead to net distribution of INR 8,000 among 100 Participants.<br/>

                                8. The entry fee is paid and converted into our standard BB Coin which is our platform-limited virtual currency(Not a Cryptocurrency), & 1 BB Coin represents 0.01 INR or 100 BB coins = INR 1.00<br/>

                                9. A 30% of the TDS amount will be applicable and deducted as per Government/RBI Tax rules on the reward amount(Profit) is more than ₹10,000, for instance, you’ve joined a quiz where you have paid a participation fee of ₹ 100 and the reward you won is ₹11,000 so the actual amount you’ll get will be ₹ 11,000 - ₹ 100 = ₹ 10,900 - 30% = ₹ 89.7 (INR).<br/>

                                10. If the winning amount is less than 10,000 INR, then the user is not liable to pay any TDS

                                11. The Payout for the rewards the user won in the regular quizzes will be directly credited into the user's account and the user can redeem that amount directly into their bank accounts. The payout will be done using the RazorpayX payout service.<br/>

                                12. We invite users to participate in various subject or topic-based quizzes, the questions the quizzes belong to different subjects e.g. general knowledge, science, chemistry, mathematics, physics, and more. If the quiz has an entry fee, the user pays the fee and then joins in the quiz. If the user failed to join the quiz is considered eliminated, and if more than 1 user joins the quiz the amount for the joining doesn’t get refunded. If only 1 user joins the quiz, at the end of the quiz his amount is refunded or won by him because no other users joined that quiz.<br/>


                            </span>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    )
}

export default Regulation
