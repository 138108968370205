import React, { useEffect, useState } from 'react'
import mobileView from '../assets/mobileview.png'
import '../../src/Home/Home.css'
import Group352 from '../assets/Group_352.png'
import Group356 from '../assets/Group_356.png'
import playStore from '../assets/icons8-google-play-store-48.png'
import Group355 from '../assets/Group355.png'
import logo from '../assets/logo.png'
import { IMAGE_URL, PRERECMICRO, QUIZMICRO } from "../config/url";
import { Carousel } from 'react-bootstrap'
import { MdOutlineCalendarMonth } from 'react-icons/md'
import { GoArrowRight } from 'react-icons/go'
import { Navigate, useNavigate } from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'
const Home = () => {
    const [blog, setblog] = useState([])
    const Navigate = useNavigate()
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const page = 1;
    const limit = 10;
    const [emptyId] = useState(0);

    const latest_blog = async () => {
        try {
            const requestOptions = {
                method: "GET",
                redirect: "follow"
            };

            const response = await fetch(`${PRERECMICRO}/learning/page/get/latest/blog?page=${page}&limit=${limit}`, requestOptions)
            const result = await response.json()
            if (result.status == 1) {
                setblog(result.blogs)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const hanldeInstallClick = () => {
        console.log('clicked')
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                console.log("then here")
                if (choiceResult.outcome === 'accepted') {
                    console.log('user accepted the install prompt');
                } else {
                    console.log('user accepted the install prompt');

                }
                setDeferredPrompt(null);
            })
        }
        else {
            window.location.href = 'https://app.brainbucks.co.in/downloadapk';
        }
    }
    //   const handleClick = () => {
    //     navigate(`/view/show/blog/${item?._id}`);
    //   };
    useEffect(() => {
        latest_blog(1, 10)
    }, [])
    return (
        <div>
            <div className='example' style={{ width: "100%", overflowX: "hidden", display: "flex", flexDirection: "column" }}>
                {/* Navbar */}
                <div style={{ height: "7vh" }}>
                    <Header />

                </div>
                <div className='border' style={{}}>
                    <div className='container-fluid ' style={{ backgroundColor: "#903BFF", paddingTop: "50px" }}>
                        <div className='row'>
                            <div className='col-lg-12 col-sm-12 text-center text-white india'>
                                <h1 style={{ fontWeight: "700" }}>Introducing Brain Bucks </h1>
                                <p>India’s<span style={{ color: "#FFE609" }}> First EdTech Platform </span> with Rewards</p>
                                <div className='mobileView'>
                                    <img src={mobileView} className='cssanimation sequence fadeInBottom' style={{ width: "100%" }}></img>
                                </div>
                                <div className="ss1">

                                    <button type="button" onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.brainbucks.android'} class="DownloadAPK my-4 text-nowrap" style={{ width: "auto" }}>Download App  
                                        <img alt='playstore' src={playStore} style={{ width: "25px", marginLeft: "10px" }} /> 
                                     </button>
                                </div>
                                <div className="Crack" style={{ display: "flex", justifyContent: "center", padding: "0px 0px 0px 30px" }}>
                                    <h2 style={{ color: "#fff" }}>Crack</h2>
                                    <div className='scrolling-contain'>
                                        <ul style={{ textAlign: "left", color: "FFE609" }}>
                                            <li>Banking</li>
                                            {/* <li>Defence</li>
                      <li>PSU</li>
                      <li>SSC</li>
                      <li>UPSC</li>
                      <li>Teaching</li>
                      <li>Railways</li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* section first */}
                    <section>
                        <div className='container mt-5'>
                            <div className='row'>
                                <div className='col-lg-2 col-md-12 col-sm-12'></div>
                                <div className="col-lg-4 col-md-12 col-sm-12 Quiz pt-5">
                                    <h1 style={{ color: "#2E2E2E" }}>Earn Rewards for      Every Quiz you <br /> Participate</h1>

                                    <div className="MBUCKS">
                                        <button type="button" onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.brainbucks.android'} className="my-4 mx-1 Bucks ">Download Brain Bucks
                                        <img alt='playstore' src={playStore} style={{ width: "25px", marginLeft: "10px" }} /> 
                                        </button>
                                    </div>

                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <img src={Group352} className="you p-5" style={{ width: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section second */}
                    <section>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-2 col-md-12 col-sm-12'></div>
                                <div class="col-lg-4 col-md-12 col-sm-12 order-last order-md-second">
                                    <img src={Group352} className="you p-5" style={{ width: "100%" }} />
                                </div>

                                <div className="col-lg-3 col-md-12 col-sm-12 order-first order-md-last    Quiz1   pt-5">
                                    <h1 style={{ color: "#2E2E2E" }}>Play Free <br /> Trivia & Earn  <br /> upto ₹ 50,000</h1>

                                    <div className="MBUCKS">
                                        <button type="button" onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.brainbucks.android'} className="my-4 mx-1 Bucks ">Download Brain Bucks <img alt='playstore' src={playStore} style={{ width: "25px", marginLeft: "10px" }} /> </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>
                    {/* Third section */}
                    {/* <section>
                        <div className='container mt-5'>
                            <div className='row'>
                                <div className='col-lg-2 col-md-12 col-sm-12'></div>
                                <div className="col-lg-8 col-md-12 col-sm-12 Quiz pt-5">
                                    <h1 style={{ color: "#2E2E2E" }}>Redeem Balance For Paid Courses.</h1>

                                    <div className="MBUCKS">
                               <button type="button"  onClick={hanldeInstallClick} className="my-4 mx-1 Bucks ">Download Brain Bucks</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </section> */}
                    {/* four section */}
                    <section style={{ backgroundColor: " #903BFF", color: " #FFF", padding: " 30px 0px" }}>
                        <div className="container  ">
                            <div className="row">
                                <div className="col-lg-2 col-sm-12"> </div>
                                <div className="col-lg-4 col-md-12 col-sm-12  Make">

                                    <h1 style={{ fontWeight: "600" }}>Make <span style={{ color: "#FFE609" }}>Rooms</span> &amp;<br /> Compete With <br />Your
                                        <span style={{ color: "#FFE609" }}>Friends</span></h1>

                                    <div className="roomms">
                                        <button type="button" className="Rooms my-4 text-nowrap" onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.brainbucks.android'} style={{ width: "auto" }}>Download App 
                                        <img alt='playstore' src={playStore} style={{ width: "25px", marginLeft: "10px" }} /> 
                                        </button>
                                    </div>
                                </div>


                                <div className="col-lg-4 col-md-12 col-sm-12  Make">
                                    <img src={Group355} className=" you p-5" style={{ width: "100%" }} />

                                </div>
                            </div>
                        </div>

                    </section>
                    {/* end make section */}
                    <section>
                        <div className='container'>
                            <div className='row'>
                                <div className="col-lg-2 col-md-12 col-sm-12  order-last order-md-first">
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 order-last order-md-first   ">
                                    <img src={Group356} class="you p-5" style={{ width: "100%" }} />
                                </div>
                                <div class="col-lg-3 col-md-12 col-sm-12   Quiz2   pt-5"><h1 style={{ color: " #2E2E2E" }}>Free Study <br /> Materials and <br />  Topper’s <br />Notes </h1>
                                    <div>
                                        <button
                                            type="button"
                                            className="my-4 mx-1 Bucks order-first order-md-last"
                                            onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.brainbucks.android'}
                                        >
                                            Download Brain Bucks   <img alt='playstore' src={playStore} style={{ width: "25px", marginLeft: "10px" }} /> 
                                        </button>
                                    </div></div>


                            </div>
                        </div>
                    </section>
                    {/* end section */}
                    <section>
                        <div className='container'>
                            <div className='row'>
                                <div className="col-lg-2 col-md-12 col-sm-12">
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12   Preferences   pt-5">
                                    <h1 style={{ color: "#2E2E2E" }}>Personalize Brain Bucks according to your Preferences </h1>  <div>
                                        <button type="button" className="  my-4 mx-1 Bucks order-first order-md-last" onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.brainbucks.android'}>Download Brain Bucks   <img alt='playstore' src={playStore} style={{ width: "25px", marginLeft: "10px" }} /> </button>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12 col-sm-12">
                                    <img src={Group356} class="you p-5" style={{ width: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Latest Blogs */}
                    <section style={{ backgroundColor: '#f2f2f2' }}>
                        <div className='container mt-5 '>
                            <div class="col-lg-10 col-sm-12 mt-4 m-auto">
                                <h1 style={{ fontSize: "45px", fontWeight: "700", textAlign: 'center', color: "#2E2E2E", padding: "30px 0 30px 0px" }}>Read our Latest Blogs <br /> Remain Updated</h1>
                                <Carousel interval={3000} pause={false} className='custom-carousel'>
                                    {blog &&
                                        blog.map((res, index) => {
                                            if (index % 4 === 0) {
                                                return (
                                                    <Carousel.Item key={index}>
                                                        <div className='row'>
                                                            {blog.slice(index, index + 3).map((item, i) => (
                                                                <div className='col-lg-4 col-sm-12'>
                                                                    <div className="card " style={{ width: "100%" }}>
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                border: "1px solid #808080",
                                                                                height: "200px",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src=
                                                                                {IMAGE_URL + item.banner}
                                                                                style={{
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                    objectFit: "cover",
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div class="card-body d-flex flex-column ">
                                                                            <h6 className="">{item.title}</h6>
                                                                            <div className=' mt-4 d-flex align-items-center justify-content-between'>
                                                                                <div className='d-flex '>
                                                                                    <div className='d-flex gap-2 '>
                                                                                        <img src={logo} style={{ height: "3vh" }}></img>
                                                                                        <span className='new-txt'>Brainbucks</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex align-items-center gap-2'>
                                                                                    <MdOutlineCalendarMonth className='txx' style={{ fontSize: "18px" }} />
                                                                                    <span className='txx'>{item.scheduleDateTime}</span>
                                                                                </div>

                                                                            </div>
                                                                            <button type='button' className='contributor ' onClick={() => { Navigate(`/view/show/blog/${item?._id}`) }}>Read Complete Blog</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Carousel.Item>
                                                );
                                            }
                                            return null;
                                        })}
                                </Carousel>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }} onClick={() => { Navigate(`/view/all/blog/${emptyId}`) }}>
                            <button type="button" className="View mt-5 mb-5"> View All Blogs</button>
                        </div>
                        {/* <div className='row d-flex justify-content-center  mt-5'>
                            <div className=' col-6  d-flex justify-content-center align-items-center '>
                                <div className=' p-4' onClick={() => { Navigate("/view/all/blog") }}>
                                    <button type='button' className='contributor'>View all Blogs</button>
                                    <GoArrowRight className='arrow' />
                                </div>
                            </div>
                        </div> */}
                    </section>
                    <section style={{ backgroundColor: "#701DDB", color: "#FFF" }}>
                        <div className='container py-5'>
                            <div className='row'>
                                <div className=''>
                                    <h2 className='text-center pb-5'>Our Number</h2>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-12"></div>
                                <div class="col-lg-3 col-md-6 col-sm-12 mt-4">
                                    <div>
                                        <h1 class="counter-value  text-center" data-count="100000">1000+</h1>
                                        <h5 class="text-center mt-3" style={{ color: "#FFE609" }}>Pre-Registrations</h5>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-12 mt-4">
                                    <div>
                                        <h1 class="counter-value  text-center" data-count="100000">5000+</h1>
                                        <h5 class="text-center mt-3" style={{ color: "#FFE609" }}>Community on Social Media
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-12 mt-4">
                                    <div>
                                        <h1 class="counter-value  text-center" data-count="100000">25+</h1>
                                        <h5 class="text-center mt-3" style={{ color: "#FFE609" }}>Stakeholders & Team Members</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </div>
            < Footer />

        </div>


    )
}

export default Home