import React, { createContext, useContext, useState } from 'react';

// Create context
const SearchContext = createContext();

// Create provider component
export const SearchProvider = ({ children }) => {
  const [searchBlogData, setSearchBlogData] = useState("");

  return (
    <SearchContext.Provider value={{ searchBlogData, setSearchBlogData }}>
      {children}
    </SearchContext.Provider>
  );
};

// Custom hook to consume search state
export const useSearch = () => useContext(SearchContext);
