import React from 'react'
import { FaLinkedin } from 'react-icons/fa'
import Header from '../Header'
import Footer from '../Footer'
import img1 from '../assets/BrainBucksBanner.png'
import img2 from '../assets/img-2.png'
import img3 from '../assets/img-3.png'
import img4 from '../assets/img-4.png'
import '../update/update.css'

const Update = () => {
    return (
        <div>
            <div style={{ width: "100%", overflow: "hidden", display: "flex", flexDirection: "column" }}>
                {/* Navbar */}
                <div style={{ height: "7vh" }}>

                    <Header />
                </div>

                {/* Main Content */}
                <div className='border' style={{ overflowY: "auto" }}>
                    <div className='container p-5'>



                        <div className='row  mt-3'>
                            <div className='col-12'>
                                <div className='row '>
                                    <div className='col-lg-3 col-sm-12'>
                                        <div class="sticky-div">
                                            <h2>Versions</h2>
                                            <div>
                                                <div class="d-flex" role="search">
                                                    <a href="https://brainbucks.co.in/public/apk/brainbucks.apk">
                                                        <button type="button" class="dwn">Brainbucks 1.1</button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className='col-lg-7 '>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <span style={{ fontSize: "25px", fontWeight: "600" }}>Brain bucks Version 1.1</span>
                                            </div>
                                            <div className=' d-flex align-items-center'>
                                                <div class=" " role="search">
                                                    <a href="https://brainbucks.co.in/public/apk/brainbucks.apk"> <button type="button" class="dwn">Update</button></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='pt-4'>
                                            {/* Assuming img1 is the import of an image */}
                                            <img src={img1} alt="Image 1" style={{ width: "100%", height: "100%" }} />
                                        </div>


                                        {/* <div>
                          <img src={img1} alt="Image 1" style={{ width: "100%", height: "100%" }} />
                        </div> */}

                                        <div class="d-flex mt-3" role="search">
                                            <a href="https://brainbucks.co.in/public/apk/brainbucks.apk"> <button type="button" class="dwn2">In Production since 23 May 2023 </button></a>
                                        </div>

                                        <div className='pt-3'>
                                            <span style={{ fontSize: "25px", fontWeight: "600" }}>Release Notes</span>
                                            <p style={{ fontSize: "18px", lineHeight: "24px" }}>Welcome to release of Brain Bucks Version 3.1.0. There are many updates in<br />
                                                this version. We hope you will like our improvements. Here are the major<br />
                                                highlights of features we updated :</p>
                                        </div>

                                        <div className='mt-5'>
                                            <ul style={{ lineHeight: "35px" }}>
                                                <a href='#edi'>
                                                    <li>Edit Rooms</li>
                                                </a>
                                                <a href='#edi'>
                                                    <li>Browse Rooms</li>
                                                </a>
                                                <a href='#edi'>
                                                    <li>Update Profile</li>
                                                </a>
                                                <a href='#edi'>
                                                    <li>Select Exams</li>
                                                </a>
                                                <a href='#edi'>
                                                    <li>Personalize your Dashboard</li>
                                                </a>
                                                <a href='#edi'>
                                                    <li>Free Study Materials</li>
                                                </a>
                                                <a href='#edi'>
                                                    <li>Password Less Login</li>
                                                </a>
                                            </ul>
                                        </div>

                                        <div className='pt-3'>
                                            <span style={{ fontSize: "25px", fontWeight: "600" }}>Edit Rooms</span>
                                            <p style={{ fontSize: "18px", lineHeight: "24px" }}>Now you can Create your Rooms and also Browse popular rooms around you. Not only this we have added Room types as “Public” & “Private”. Anyone can join public room and participants can  send the room joining request to the Leader of Room & Leader can Approve the Request or reject it.</p>
                                        </div>

                                        <div className='pt-4'>
                                            <img src={img2} alt="Image 2" style={{ width: "100%", height: "100%" }} />
                                        </div>

                                        <div className='pt-3'>
                                            <span style={{ fontSize: "25px", fontWeight: "600" }}>Select Exams</span>
                                            <p style={{ fontSize: "18px", lineHeight: "24px" }}>Now you can personalize your dashboard according to the exam you are preparing for, just select the exams on registration, or else you can modify them in future as well.</p>
                                        </div>

                                        <div className='pt-4'>
                                            <img src={img3} alt="Image 3" style={{ width: "100%", height: "100%" }} />
                                        </div>

                                        <div className='pt-3'>
                                            <span style={{ fontSize: "25px", fontWeight: "600" }}>Update Profile</span>
                                            <p style={{ fontSize: "18px", lineHeight: "24px" }}>Now you can update your avatar, Bank Details, Sharing, Email, Preferences and much more according to your preferences, now no need to worry about customization, Brain Bucks is now “Your Brainbucks”.</p>
                                        </div>

                                        <div className='pt-4'>
                                            <img src={img4} alt="Image 4" style={{ width: "100%", height: "100%" }} />
                                        </div>

                                    </div>
                                </div>


                            </div>
                            {/* <Footer /> */}
                        </div>



                    </div>
                </div>
            </div>
            <Footer /> {/* Place Footer component here to close the component */}
        </div>
    );
}

export default Update;


