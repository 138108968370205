import React, { useEffect, useState } from "react";
import { faCalendarDays } from '@fortawesome/free-brands-svg-icons'
import Header from "../Header";
import Footer from "../Footer";
import { IMAGE_URL, PRERECMICRO } from '../config/url'
import Blog from "../blogs/Blog";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import logo from "../assets/logo.png"
import Group357 from "../assets/Group357.png"

const ShowBlog = () => {
    const Navigate = useNavigate();
    const [showblog, setshowBlog] = useState([])
    const { id } = useParams();


    const view_AllBlogs = async () => {
        try {
            const requestOptions = {
                method: "GET",
                redirect: "follow"
            };
            const response = await fetch(`${PRERECMICRO}/learning/page/get/particular/blog?blog_id=${id}`, requestOptions);
            const result = await response.json();
            if (result.status === 1) {
                if (result.data.length === 0) {
                    console.log("No data available");
                } else {
                    setshowBlog(result.data);
                }
            } else {
                console.error("Error fetching data:", result.message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        view_AllBlogs()
    }, [])

    console.log("dsd");

    return (
        <div>
            <div style={{ width: "100%", overflow: "hidden", display: "flex", flexDirection: "column" }}>
                {/* Navbar */}
                <div style={{ height: "7vh" }}>
                    <Header />
                </div>
                <div className='border' style={{ overflowY: 'hidden' }}>
                    <div className="container p-5">
                        <div className="row">
                            {showblog?.map((res, item) => {
                                const formattedString = res.body.replace(/\n/g, '<br />');
                                return (
                                    <>
                                        <div className="col-lg-8 col-sm-12 suddenly ">
                                            <h1>{res.title}</h1>
                                            <div className="mt-3 " style={{ display: "flex" }}>
                                                <p style={{ color: " #9b9b9b", display: "flex", fontSize: "14px" }}><img src={logo} className="me-2" style={{ width: "25px" }} /> BrainBucks</p>
                                                <p className="mx-3" style={{ color: "#9b9b9b", display: "flex", alignItems: "center", fontSize: "14px" }}>
                                                    {/* <FontAwesomeIcon icon={faCalendarDays} />  */}
                                                    {res.scheduleDateTime}</p>
                                            </div>
                                            <div className="mt-3 " >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        //   border: "1px solid #808080",
                                                        // height: "50vh",
                                                    }}
                                                >
                                                    <img
                                                        src={IMAGE_URL + res.banner}
                                                        style={{
                                                            width: "100%",
                                                            // height: "100%",
                                                            objectFit: "cover",
                                                        }}
                                                        alt="icon"
                                                    />
                                                </div>
                                                <p
                                                    className="mt-2"
                                                    dangerouslySetInnerHTML={{ __html: formattedString }}
                                                />
                                            </div>


                                        </div>
                                        <div className="col-lg-4 col-sm-12 " >
                                            <div className="fixed-top" style={{ position: 'sticky', zIndex: "0" }}>
                                                <div className="card suddenlycard" style={{ boxSizing: 'border-box' }}>
                                                    <div className="card-body">
                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <h4 style={{ fontWeight: "600" }}>Related Blogs</h4>
                                                            <button type="button" className="btn abd mb-1" onClick={() => { Navigate(`/view/all/blog/${res?._id}`) }}> View All</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={Group357} class="mt-3" style={{ width: " 100%" }} alt="icon" />
                                            </div>
                                        </div>
                                    </>

                                )
                            })}



                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ShowBlog