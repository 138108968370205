import React from 'react'
import { BiLeftArrowAlt } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const navigateBack = () => {
        navigate(-1);
    };
    return (
        <div>
            <div className='container-fluid'>
                <div className='row pt-4 ps-2'>
                    <div className='col-12'>
                        <div
                            style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                border: "1px solid black",
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "white",
                            }}
                            onClick={navigateBack}
                        >
                            <BiLeftArrowAlt size="cssUnit" style={{ color: "black" }} />
                        </div>


                        <div className='mt-4'>
                            <h3>Privacy Policy</h3>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} > <span style={{ fontWeight: "bold" }}>Tech Brainbucks Infosoft Private Limited </span>
                                Operates the web portal by the name of Brainbucks.in in india. Which offers online education QUIZ/Scholarship tests and Online test series for all competive exams through the web-portal brainbucks.in and associated facebook application, and mobile applications (collectively reffered to as the ‘’Portal’’) (Tech Brainbucks Infosoft Private Limited  Referred to herein as ‘’Brainbucks.in’’ or ‘’we’’ or ‘’us’’ ‘’our’’)<br />

                                Any person utilizing the portal (‘’user’’ or you’ or ‘your ) or any of its features including participation in the various contests, quizs (Including scholarship tests) being conducted on the portal. Shall be bound by this privacy policy.<br />

                                Tech Brainbucks Infosoft Private Limited respects the privacy of its users and is committed to protect it in all respects. With a view to offer an enriching and holistic internet experience to its users, Brainbucks offers a vast repository of amusment facilities. Kindly take time to read the ‘about us’ section to know more about Brain bucks . Most of the Quizs are offered for free but you may need registration to participate in Brain bucks online quizs. The information about the User is collected by Brainbucks  as (i) information supplied by Users and (ii) information automatically tracked during User's navigation on Brainbucks.<br />

                                Before you submit any information to the Portal, please read this Privacy Policy for an explanation of how we will treat your personal information. By using any part of the Portal, you consent to the collection, use, disclosure and transfer of your personal information for the purposes outlined in this Privacy Policy and to the collection, processing and maintenance of this information. If you do not agree to this Privacy Policy, please do not use the Portal. Your use of any part of the Portal indicates your acceptance of this Privacy Policy and of the collection, use and disclosure of your personal information in accordance with this Privacy Policy. While you have the option not to provide us with certain information or withdraw consent to collect certain information, kindly note that in such an event you may not be able to take full advantage of the entire scope of features and services offered to you and we reserve the right not to provide you with our services.<br />
                            </span>
                        </div>




                        <div className='mt-4'>
                            <h3>Purpose and Usage</h3>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }}>
                                To avail certain quiz Facilities on the Portal, Users would be required to provide certain information for the registration process namely:<br />

                                1. Username<br />
                                2. Password<br />
                                3. Email address<br />
                                4. Date of birth<br />

                                In the course of providing you with access to the Quiz, and in order to provide you access to the features and services offered through the Portal and to verify your identity, you may be required to furnish additional information, including your Permanent Account Number.<br />



                                In the course of providing the Amusement Facilities, Users may invite other existing Users or other users (“Invited Users”) to participate in any of the Quiz offering by brainbucks  by providing the email address or Facebook username of such users. Tech Brainbucks Infosoft Private Limited  may thereafter use this information to contact the Invited User and invite such user to register with Brainbucks.in  (if such Invited User is not a existing User) and participate in the Quiz/Scholarship test in relation to which such person was invited by the User. The participation of the Invited User in any of the Amusement Facilities shall be subject to the terms of this Privacy Policy and the Terms and Conditions (LINK BASE URL_terms and conditions) for the use of the Portal. The User hereby represents that the Invited Users have consented and agreed to such disclosure to and use of their email address and Facebook username by Brainbucks.<br />

                                All required information is specific and based on the kind of Quiz/ Scholarship test  the User wishes to participate in or access, and will be utilized for the purpose of providing services, including but not limited to the Quiz, requested by the User. The information as supplied by the Users enables us to improve and provide you the most user-friendly Quiz  experience. Brainbucks  may also share such information with affiliates and third parties in limited circumstances, including for the purpose of providing services requested by the User, complying with legal process, preventing fraud or imminent harm, and ensuring the security of our network and services.<br />
                            </span>
                        </div>




                        <div className='mt-4'>
                            <h3>Use of Cookies :</h3>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                To improve the effectiveness and usability of the Portal for our Users, we use "cookies", or such similar electronic tools to collect information to assign each visitor a unique random number as a User Identification (User ID) to understand the User's individual interests using the identified computer. Unless the User voluntarily identifies himself/herself (e.g., through registration), Tech Brainbucks Infosoft Private Limited (Brainbucks.in)  has no way of knowing who the User is, even if we assign a cookie to the User's computer. The only personal information a cookie can contain is information supplied by the User. A cookie cannot read data off the User's hard drive. Brainbucks advertisers may also assign their own cookies to the User's browser (if the User clicks on their ad banners), a process that Brainbucks (Tech Brainbucks Infosoft Private Limited)  does not control.<br />

                                Brainbucks's web servers automatically collect limited information about User's computer's connection to the Internet, including User's IP address, when the User visits the Portal. (User's IP address is a number that lets computers attached to the Internet know where to send data to the User -- such as the web pages viewed by the User). The User's IP address does not identify the User personally. Brainbucks uses this information to deliver its web pages to Users upon request, to tailor its Portal to the interests of its users, to measure traffic within the Portal and let advertisers know the geographic locations from where Brainbucks's visitors come.
                            </span>
                        </div>


                        <div className='mt-4'>
                            <h3>Data Collection via Branch SDK:</h3>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >

                                Our mobile application utilizes Branch.io's deep linking technology to provide enhanced user experiences, such as seamless navigation to specific screens, referral code tracking, and targeted content delivery. As part of this functionality, we collect and utilize certain device information for the purpose of deep linking.<br />


                                Data Collection:<br />

                                When you use our app, we collect and store the Android device ID (also known as Android Advertising ID) as provided by Google Play services. This identifier is used solely for the purpose of identifying and targeting deep links within our app. It enables us to provide personalized experiences, such as attributing referral codes, facilitating seamless sign-up processes, and directing users to specific screens relevant to their interactions.<br />

                                Usage of Device Information:<br />

                                The Android device ID collected by Branch.io is solely utilized within our app and is not shared with any third parties for advertising or marketing purposes. We do not use the Android device ID to personally identify users outside of the context of our app. The collected information is used exclusively for improving user experiences, analyzing app performance, and delivering personalized content.<br />

                                Data Security:<br />

                                We take data security and user privacy seriously. All device information collected through Branch.io's deep linking technology is stored securely and protected against unauthorized access, loss, or misuse. We have implemented appropriate technical and organizational measures to ensure the confidentiality and integrity of the collected data.<br />

                                User Control and Opt-Out:<br />

                                We respect your privacy and provide you with control over the data we collect. You can reset your Android Advertising ID or limit ad tracking through the settings on your Android device. By doing so, you may experience a generic deep linking experience that is not tailored to your specific preferences or interactions.<br />

                                Updates to Privacy Policy:<br />

                                We may update our privacy policy from time to time to reflect changes in our data practices or legal requirements. We encourage you to review this policy periodically to stay informed about how we collect, use, and protect your information.<br />
                                For further reference, you can review Branch.io's privacy policy at Branch.io Privacy Policy. Their privacy policy provides additional information on how Branch.io handles data collection, usage, and security.
                            </span>
                        </div>


                        <div className='mt-4'>
                            <h3>Links :</h3>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                Brainbucks also includes links to other websites. Such websites are governed by their respective privacy policies, which are beyond Brainbucks's control. Once the User leaves Brainbucks's servers (the User can tell where he/she is by checking the URL in the location bar on the User's browser), use of any information provided by the User is governed by the privacy policy of the operator of the site which the User is visiting. That policy may differ from Brainbucks's own. If the User can't find the privacy policy of any of these sites via a link from the site's homepage, the User may contact the site directly for more information. Tech Brainbucks Infosoft Private Limited (Brainbucks) is not responsible for the privacy practices or the content of such websites.
                            </span>
                        </div>

                        <div className='mt-4'>
                            <h3>Security Procedures:</h3>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                All information gathered on Brainbucks is securely stored within Tech Brainbucks Infosoft Private Limited - controlled database. The database is stored on servers secured behind a firewall; access to such servers being password-protected and strictly limited based on need-to-know basis. However, we understand that as effective as our security measures are, no security system is impenetrable. Thus, we cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. Further, any information you include in a posting to the discussion areas will be available to anyone with Internet access. By using the Portal, you understand and agree that your information may be used in or transferred to countries other than India.<br />

                                Brainbucks also believes that the internet is an ever-evolving medium. We may periodically review from time to time and change our privacy policy to incorporate such future changes as may be considered appropriate, without any notice to you. Our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be. Any changes to our privacy policy will be posted on this page, so you are always aware of what information we collect, how we use it, how we store it and under what circumstances we disclose it.
                            </span>
                        </div>

                        <div className='mt-4'>
                            <h3>Advertising:</h3>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                When Brainbucks presents information to it's online advertisers -- to help them understand our audience and confirm the value of advertising on the Portal -- it is usually in the form of aggregated statistics on traffic to various pages within our site. When you register with Brainbucks, we contact you from time to time about updating your content to provide features which we believe may benefit you.<br />

                                Several deceptive emails, websites, blogs etc. claiming to be from or associated with Brainbucks may or are circulating on the Internet. These emails, websites, blogs etc. often include our logo, photos, links, content or other information. Some emails, websites, blogs etc. call the user to provide login name, password etc. or that the user has won a prize/ gift or provide a method to commit illegal/ unauthorized act or deed or request detailed personal information or a payment of some kind. The sources and contents of these emails, websites, blogs etc. and accompanying materials are in no way associated with Tech Brainbucks Infosoft Private Limited (Brainbucks) . For your own protection, we strongly recommend not responding to emails or using websites, blogs etc. We may use the information provided by you to Brainbucks web- portal or its Application , including your email address or phone number, to contact you about the Quiz and scholarship test Facilities or other services availed by you or to inform you of our Facilities and services.
                            </span>
                        </div>

                        <div className='mt-4'>
                            <h3>Conditions of Use:</h3>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                Tech Brainbucks Infosoft Private Limited  (Brainbucks)  DOES NOT WARRANT THAT THIS PORTAL, IT’S SERVERS, OR EMAIL SENT BY US OR ON OUR BEHALF ARE VIRUS FREE. Tech Brainbucks Infosoft Private Limited  (Brainbucks)  WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS PORTAL, INCLUDING, BUT NOT LIMITED TO COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL AND CONSEQUENTIAL DAMAGES, LOSS OF DATA, GOODWILL, BUSINESS OPPORTUNITY, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES. IN NO EVENT WILL Tech Brainbucks Infosoft Private Limited  (Brainbucks)  BE LIABLE FOR ANY DAMAGES WHATSOEVER IN AN AMOUNT IN EXCESS OF AN AMOUNT OF INR 100.
                            </span>
                        </div>


                        <div className='mt-4'>
                            <h3>Retention of Data:</h3>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                Your personal information may be retained and may continue to be used until: (i) the relevant purposes for the use of your information described in this Privacy Policy are no longer applicable; and (ii) we are no longer required by applicable law, regulations, contractual obligations or legitimate business purposes to retain your personal information and the retention of your personal information is not required for the establishment, exercise or defense of any legal claim.
                            </span>
                        </div>

                        <div className='mt-4'>
                            <h3>Applicable Law and Jurisdiction:</h3>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                By visiting brainbucks’s Portal, you agree that the laws of the Republic of India without regard to its conflict of laws principles, govern this Privacy Policy and any dispute arising in respect hereof shall be subject to and governed by the dispute resolution process set out in the Terms and Conditions. 
                            </span>
                        </div>

                        <div className='mt-4'>
                            <h3>Updating Information:</h3>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >

                                You will promptly notify Brainbucks if there are any changes, updates or modifications to your information. Further, you may also review, update or modify your information and user preferences by logging into your Profile page on the Portal.


                            </span>
                        </div>


                        <div className='mt-4 mb-2'>
                            <h3>Contact Us:</h3>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >

                                Any questions or clarifications with respect to this Policy or any complaints, comments, concerns or feedback can be sent to Brainbucks at: helpdesk@brainbucks.in or by normal/physical mail addressed to:<br />

                                Attn: Brainbucks Team <br />

                                Tech Brainbucks Infosoft Private Limited<br />
                                101-201-301, Chanda Tower, Girnar Colony, Gandhi Path Road, Vaishali Nagar, Jaipur - 302021


                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // 
    )
}

export default PrivacyPolicy
