import React, { useEffect, useState } from 'react';
import './Header.css';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import BrainLogo from '../src/assets/BrainLogo.png'
import playStore from './assets/icons8-google-play-store-48.png'

const Header = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { pathname } = useLocation();


  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event)
    };
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }
  }, [])

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const hanldeInstallClick = () => {
    console.log('clicked')
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        console.log("then here")
        if (choiceResult.outcome === 'accepted') {
          console.log('user accepted the install prompt');
        } else {
          console.log('user accepted the install prompt');

        }
        setDeferredPrompt(null);
      })
    }
    else {
      window.location.href = 'https://app.brainbucks.co.in/downloadapk';
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const Navigate = useNavigate()
  return (
    <section>
      <nav className="navbar navbar-expand-lg  fixed-top  " style={{ backgroundColor: "#2E2E2E" }}>

        <div className="container ">

          <img src={BrainLogo} onClick={() => { Navigate("/") }} style={{ cursor: 'pointer' }} />
          <button
            className={`navbar-toggler ${isCollapsed ? '' : 'collapsed'}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={!isCollapsed}
            aria-label="Toggle navigation"
            style={{ backgroundColor: 'rgb(101 101 101)' }}
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0  ">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" href="" id="about-us" to="/about">About Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="" id="blogs" to="/blog">Blogs</Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" href="" id="updates" to="/update">Updates</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="" id="team" to="/team">Team</Link>
              </li>
            </ul>
            <div className="d-flex" role="search">

              <button onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.brainbucks.android'} type="button" className="Download">Download App 
                <img alt='playstore' src={playStore} style={{width: "25px", marginLeft: "10px"}} 
                /></button>

            </div>
          </div>
        </div>
      </nav>
    </section>
  );
}

export default Header;