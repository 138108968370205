import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import logo from './assets/BrainLogo.png'
import vector from './assets/Vector (17).png'
import playStore from './assets/icons8-google-play-store-48.png'
import { Navigate, useNavigate } from 'react-router-dom';

const Footer = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const Navigate = useNavigate()
    const hanldeInstallClick = () => {
        console.log('clicked')
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                console.log("then here")
                if (choiceResult.outcome === 'accepted') {
                    console.log('user accepted the install prompt');
                } else {
                    console.log('user accepted the install prompt');

                }
                setDeferredPrompt(null);
            })
        }
        else {
            window.location.href = 'https://app.brainbucks.co.in/downloadapk';
        }
    }
    useEffect(() => {
        const footerLinks = document.querySelectorAll('.footer a, .footer p');

        footerLinks.forEach(link => {
            link.addEventListener('click', () => {
                window.scrollTo(0, 0);
            });
        });

        return () => {
            footerLinks.forEach(link => {
                link.removeEventListener('click', () => {
                    window.scrollTo(0, 0);
                });
            });
        };
    }, []);
    return (
        <section className="footer example" style={{ backgroundColor: "#2E2E2E", overflow: 'hidden' ,position: "sticky" }}>
            <div className="container-fluid " >
                <div className="row ">
                    <div className="col-lg-2 col-sm-12"></div>
                    <div className="col-lg-2 col-sm-12 mt-4">
                        <a href="https://brainbucks.co.in">
                            <img src={logo} />
                        </a>
                        <div className="mt-3" style={{ color: "#FFF", fontSize: "22px", cursor: "pointer" }}>
                            <a href="https://www.instagram.com/brainbucks_india/" style={{ color: "#FFF", padding: " 0 12px 0px 0px" }}>
                                <FontAwesomeIcon icon={faSquareInstagram} />                                </a>
                            <a href="https://www.facebook.com/brain.bucks.98" style={{ color: "#FFF", padding: " 0 12px 0px 0px" }}> <FontAwesomeIcon icon={faFacebook} /></a>
                            <a href="https://www.youtube.com/@BrainBucksIndia" style={{ color: "#FFF", padding: " 0 12px 0px 0px" }}><FontAwesomeIcon icon={faYoutube} /></a>
                            <a href="https://www.linkedin.com/company/brainbucksindia/" style={{ color: "#FFF", padding: " 0 12px 0px 0px" }}><FontAwesomeIcon icon={faLinkedin} /></a>
                            <div className="mt-3">
                                <span style={{ textDecoration: "none", color: "#fff", fontSize: "14px" }}>Bussines Name : Tech Brainbucks Infosoft Private Limited</span> <br />
                                <a href="https://goo.gl/maps/TPgi63uQo9keyG4r6" style={{ textDecoration: "none", color: "#fff", fontSize: "14px" }}><span style={{ fontSize: "14px" }}>Head Office : <br />  101-103, Chanda Tower,  <br />   Vaishali Nagar, Jaipur, <br />  RJ - 302021 <br /></span>  </a>
                                <span style={{ fontSize: "14px" }}>E-Mail : info@brainbucks.co.in</span>

                                <span style={{ fontSize: "14px" }}> Phone : +91-788 794 0832 </span>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-12 mt-4 khattu" style={{ color: "#888888", fontWeight: "400", textDecrotion: "none", cursor: "pointer" }}>
                   <p onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.brainbucks.android'}>Download App   <img alt='playstore' src={playStore} style={{ width: "20px", marginLeft: "5px" }} /> </p>
                        <p onClick={() => { Navigate("/") }}>How to Play ?</p>
                        <p onClick={() => { Navigate("/about") }}>About Us</p>
                        <p onClick={() => { Navigate("/blog") }}>Blogs</p>
                    </div>
                    <div className="col-lg-2 col-sm-12 mt-4 khattu"  style={{ color: "#888888", fontWeight: "400", textDecrotion: "none", cursor: "pointer" }}>
                        <p onClick={() => { Navigate("/update") }}>Updates</p>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="2" placeholder=" message" style={{ backgroundColor: "#2E2E2E", color: "#888", boxShadow: "none", border: "1px solid #888", width: "90%" }}></textarea>

                        <button type="button" className="mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ padding: " 8px 12px", borderRadius: " 6px", backgroundColor: "#2E2E2E", color: "#888", boxShadow: "none", border: "1px solid #888" }}>Submit Feedback</button>
                    </div>
                    <div className="col-lg-3 col-sm-12 mt-4 khattu"style={{ color: "#888888", fontWeight: "400", textDecrotion: "none",cursor: "pointer"  }} >
                        <p onClick={() => { Navigate("/privacy/policy") }}>Privacy Policy</p>
                        <p onClick={() => { Navigate("/regulation") }}>Rules &amp; Regulation</p>
                        <p onClick={() => { Navigate("/terms/condition") }}>Terms &amp; Conditions</p>
                        <p onClick={() => { Navigate("/privacy/policy") }}>User License Agreement</p>
                        <p onClick={() => { Navigate("/refund/cancellation/policy") }}>Refund/Cancellation Policy</p>
                        <p onClick={() => { Navigate("/deleteaccount") }}>Delete Account</p>
                    </div>

                </div>
                <div className="row  mt-4" style={{ backgroundColor: "#000" }}>
                    <div className="col-lg-12 col-sm-12 mb-1">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <span className="text-white p-3" style={{ fontSize: "14px" }}> <img src={vector} />  All Rights Reserved | Tech Brainbucks Infosoft Private Limited</span>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Footer;