import React, { useContext, useEffect, useState } from "react";
import { MdOutlineCalendarMonth } from "react-icons/md";
import logo from "../assets/logo.png";
import Header from "../Header";
import { IMAGE_URL, PRERECMICRO } from "../config/url";
import Footer from "../Footer";
import { useNavigate, useParams } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { useSearch } from "../ContextData/SearchContext";
import { IoCloseSharp } from "react-icons/io5";


const ViewAllBlogs = () => {
  const Navigate = useNavigate();
  const [blog, setblog] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const { id } = useParams();


  const { searchBlogData, setSearchBlogData } = useSearch();

  const latest_blog = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const ids = (id == 0) ? "" : id

    fetch(
      `${PRERECMICRO}/learning/page/get/blog?search=${searchBlogData}&page=${page}&blog_id=${ids}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 1) {
          // If it's the first page of search results, replace the existing blog state
          if (page === 1) {
            setblog(result?.final_blogs);
          } else {
            // If it's not the first page, append to the existing blog state
            setblog((prevBlogs) => [...prevBlogs, ...result?.final_blogs]);
          }
          setTotalPages(result?.total_pages);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const clearSearch = () => {

    setSearchBlogData(""); // Clear search data in context if needed
    latest_blog();
  };

  useEffect(() => {
    latest_blog();
  }, [page]);

  return (
    <div>
      <div style={{ width: "100%", display: "flex", flexDirection: "column" , overflow: "hidden"}}>
        {/* Navbar */}
        <div style={{ height: "7vh" }}>
          <Header />
        </div>

        {/* Main Content */}
        <div className="border" style={{}}>
          <div className="container">
            <div className="row mt-1">
              <div className="col-12">
                <div className="container mt-5 ">

                  <div className='row '>
                    <div className='col-lg-4 col-sm-12  m-auto mt-2  '>

                      <div class="input-group mb-3  d-flex gap-1  justify-content-center  inp ps-2 rounded">
                        <CiSearch className='mt-2' style={{ fontSize: "20px" }} />

                        <input style={{ border: "none" }} type="text" class="form-control " onChange={(e) => setSearchBlogData(e.target.value)} value={searchBlogData} placeholder="Search for a Blog" aria-label="serach for a blog" aria-describedby="button-addon2" />
                        {searchBlogData && (

                          <IoCloseSharp style={{ fontSize: "30px", margin: "auto" }} onClick={clearSearch} />
                        )}

                        <button class="btn btn-dark" type="button" onClick={latest_blog} >Search</button>
                      </div>

                    </div>
                  </div>

                  {blog?.length === 0 ? (
                    <div className="row">
                      <div className="col-12 text-center my-5"><h3>No data found....!</h3></div>
                    </div>
                  ) : (
                    <div className="row  d-flex">
                      {blog?.map((res, item) => {
                        return (
                          <>
                            <div className="col-lg-4 col-sm-12">
                              <div
                                className="card "
                                style={{ width: "100%", marginBottom: "20px", height: "400px"}}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    border: "1px solid #808080",
                                    height: "200px",
                                  }}
                                >
                                  <img
                                    src={IMAGE_URL + res.banner}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                    alt="icon"
                                  />
                                </div>
                                <div class="card-body d-flex flex-column ">
                                  <h6 className="">{res?.title}</h6>
                                  <div className=" mt-4 d-flex align-items-center justify-content-between">
                                    <div className="d-flex ">
                                      <div className="d-flex gap-2 ">
                                        <img
                                          src={logo}
                                          style={{ height: "3vh" }}
                                          alt="#"
                                        ></img>
                                        <span className="new-txt">
                                          Brainbucks
                                        </span>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <MdOutlineCalendarMonth
                                        className="txx"
                                        style={{ fontSize: "18px" }}
                                      />
                                      <span className="txx">
                                        {res?.scheduleDateTime}
                                      </span>
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    className="contributor "
                                    onClick={() => {
                                      Navigate(`/view/show/blog/${res._id}`);
                                    }}
                                  >
                                    Read as Somewhere
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}

                      <div className="center-data">
                        {blog?.length !== 0 && page !== totalPages && (
                          <button
                            className="load-button center-data p-3 px-4 rounded mt-3 mb-2"
                            onClick={handleLoadMore}
                          >
                            Load More
                          </button>
                        )}
                      </div>
                    </div>
                  )}


                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ViewAllBlogs;
