import React from 'react'
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const TermsandConditions = () => {
    const navigate = useNavigate();
    const navigateBack = () => {
        navigate(-1);
    };
    return (
        <div>
            <div className='container-fluid'>
                <div className='row pt-4 ps-2'>
                    <div className='col-12'>
                        <div
                            style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                border: "1px solid black",
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "white",
                            }}
                            onClick={navigateBack}
                        >
                            <BiLeftArrowAlt size="cssUnit" style={{ color: "black" }} />
                        </div>


                        <div className='mt-4' style={{}}>
                            <p style={{ fontWeight: "bold" }}>Terms & Conditions for Tech Brainbucks Infosoft Private Limited’s Users</p>
                            <p>Last Updated: 12 December, 2024</p>
                            <p style={{ fontWeight: "bold" }}>1. Brainbucks</p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                Brain Bucks is the flagship brand of Tech Brainbucks Infosoft Private Limited ("Brain Bucks"). Through Brainbucks, along with its sub-pages, and the Brainbucks App, Brainbucks operates single portal through which it offers collaborative quiz contest for the preparation of government job exams based in India, but Brainbucks offers these quiz contest by taking a fees known and written as “Entry Fees” or “Fees” in form of our Virtual currency “BB Coins” which is hypothetically equivalent to Indian Rupees ( ₹ ), but the usage of this currency is limited to Brainbucks app only. Brainbucks as used herein shall be construed as a collective reference to Brainbucks and the Brainbucks App.
                            </span>
                        </div>



                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>2. Usage of Brainbucks

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    Any person ("User") accessing Brainbucks or the Brainbucks App ('Brainbucks platform') for participating in the various Quiz contests (including free Trivia), available on Brainbucks platform ("Quiz(s)") ('Brainbucks Services') shall be bound by these Terms and Conditions, and all other rules, regulations and terms of use referred to herein or provided by Brainbucks in relation to any Brainbucks Services.<br />

                                o    Brainbucks shall be entitled to modify these Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Brainbucks in relation to any Brainbucks Services, at any time, by posting the same on Brainbucks. Use of Brainbucks constitutes the User's acceptance of such Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Brainbucks in relation to any Brainbucks Services, as may be amended from time to time. Brainbucks may, at its sole discretion, also notify the User of any change or modification in these Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Brainbucks, by way of sending an email to the User's registered email address or posting notifications in the User accounts. The User may then exercise the options provided in such an email or notification to indicate non-acceptance of the modified Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Brainbucks. If such options are not exercised by the User within the time frame prescribed in the email or notification, the User will be deemed to have accepted the modified Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Brainbucks<br></br>

                                o    Certain Brainbucks Services being provided on Brainbucks may be subject to additional rules and regulations set down in that respect. To the extent that these Terms and Conditions are inconsistent with the additional conditions set down, the additional conditions shall prevail<br />

                                o    Brainbucks may, at its sole and absolute discretion:<br />

                                1.  Restrict, suspend, or terminate any User's access to all or any part of Brainbucks or Brainbucks Platform Services;<br />

                                2.  Change, suspend, or discontinue all or any part of the Brainbucks Platform Services;<br />

                                3.  Reject, move, or remove any material that may be submitted by a User;<br />

                                4.  Move or remove any content that is available on Brainbucks Platform;<br />

                                5.  Deactivate or delete a User's account and all related information and files on the account;<br />

                                6.  Establish general practices and limits concerning use of Brainbucks Platform;<br />

                                7.  Offer discounts to its users in form it deems fit ("Cash Bonus"). All such discounts shall be credited in a separate account called as Cash Bonus Account<br />

                                8.  Revise or make additions and/or deletions to the roster of participants available for selection in a Quiz Contest on account of revisions to the roster of players involved in the relevant Quiz Contest;<br />

                                9.  Assign its rights and liabilities to all User accounts hereunder to any entity (post such assignment intimation of such assignment shall be sent to all Users to their registered email ids)<br />

                                o    In the event any User breaches, or Brainbucks reasonably believes that such User has breached these Terms and Conditions, or has illegally or improperly used Brainbucks or the Brainbucks Services, Brainbucks may, at its sole and absolute discretion, and without any notice to the User, restrict, suspend or terminate such User's access to all or any part of Brainbucks Contests or the Brainbucks Platform, deactivate or delete the User's account and all related information on the account, delete any content posted by the User on Brainbucks and further, take technical and legal steps as it deems necessary<br />

                                o    If Brainbucks charges its Users a platform fee in respect of any Brainbucks Services, Brainbucks shall, without delay, repay such platform fee in the event of suspension or removal of the User's account or Brainbucks Services on account of any negligence or deficiency on the part of Brainbucks, but not if such suspension or removal is effected due to:<br />

                                1.  any breach or inadequate performance by the User of any of these Terms and Conditions; or<br />

                                2.  any circumstances beyond the reasonable control of Brainbucks.<br />

                                o    Users consent to receiving communications such as announcements, administrative messages and advertisements from Brainbucks or any of its partners, licensors or associates<br />
                            </span>
                        </div>




                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>3.     Intellectual Property

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    Brainbucks includes a combination of content created by Brainbucks, its partners, affiliates, licensors, associates and/or Users. The intellectual property rights ("Intellectual Property Rights") in all software underlying Brainbucks and the Brainbucks Platform and material published on Brainbucks, including (but not limited to) games, Contests, software, advertisements, written content, photographs, graphics, images, illustrations, marks, logos, audio or video clippings and Flash animation, is owned by Brainbucks, its partners, licensors and/or associates. Users may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works of, distribute, publicly perform, publicly display, or in any way exploit any of the materials or content on Brainbucks either in whole or in part without express written license from Brainbucks<br />

                                o    Users may request permission to use any Brainbucks content by writing in to Brainbucks Helpdesk.<br />

                                o    Users are solely responsible for all materials (whether publicly posted or privately transmitted) that they upload, post, e-mail, transmit, or otherwise make available on Brainbucks ("Users' Content"). Each User represents and warrants that he/she owns all Intellectual Property Rights in the User's Content and that no part of the User's Content infringes any third party rights. Users further confirm and undertake to not display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights of any third party on Brainbucks. Users agree to indemnify and hold harmless Brainbucks, its directors, employees and assigns against all costs, damages, loss and harm including towards litigation costs and counsel fees, in respect of any third party claims that may be initiated including for infringement of Intellectual Property Rights arising out of such display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on Brainbucks, by such User or through the User's commissions or omissions<br />

                                o    Users hereby grant to Brainbucks and its affiliates, partners, licensors and associates a worldwide, irrevocable, royalty-free, non-exclusive, sub-licensable license to use, reproduce, create derivative works of, distribute, publicly perform, publicly display, transfer, transmit, and/or publish Users' Content for any of the following purposes:<br />

                                1.  displaying Users' Content on Brainbucks<br />

                                2.  distributing Users' Content, either electronically or via other media, to other Users seeking to download or otherwise acquire it, and/or<br />

                                3.  storing Users' Content in a remote database accessible by end users, for a charge.<br />

                                4.  This license shall apply to the distribution and the storage of Users' Content in any form, medium, or technology.<br />

                                o    All names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on Brainbucks(s) belonging to any person (including User), entity or third party are recognized as proprietary to the respective owners and any claims, controversy or issues against these names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights must be directly addressed to the respective parties under notice to Brainbucks.<br />
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>4.     Third Party Sites, Services and Products

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    Brainbucks may contain links to other Internet sites owned and operated by third parties. Users' use of each of those sites is subject to the conditions, if any, posted by the sites. Brainbucks does not exercise control over any Internet sites apart from Brainbucks and cannot be held responsible for any content residing in any third-party Internet site. Brainbucks's inclusion of third-party content or links to third-party Internet sites is not an endorsement by Brainbucks of such third-party Internet site.<br />

                                o    Users' correspondence, transactions/offers or related activities with third parties, including payment providers and verification service providers, are solely between the User and that third party. Users' correspondence, transactions and usage of the services/offers of such third party shall be subject to the terms and conditions, policies and other service terms adopted/implemented by such third party, and the User shall be solely responsible for reviewing the same prior to transacting or availing of the services/offers of such third party. User agrees that Brainbucks will not be responsible or liable for any loss or damage of any sort incurred as a result of any such transactions/offers with third parties. Any questions, complaints, or claims related to any third party product or service should be directed to the appropriate vendor.<br />

                                o    Brainbucks contains content that is created by Brainbucks as well as content provided by third parties. Brainbucks does not guarantee the accuracy, integrity, quality of the content provided by third parties and such content may not relied upon by the Users in utilizing the Brainbucks Services provided on Brainbucks including while participating in any of the contests hosted on Brainbucks.
                            </span>
                        </div>



                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>5.        Privacy Policy

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                All information collected from Users, such as registration and credit card information, is subject to Brainbucks's Privacy Policy which is available at Privacy Policy
                            </span>
                        </div>



                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>6.        User Conduct

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    Users agree to abide by these Terms and Conditions and all other rules, regulations and terms of use of the Website. In the event User does not abide by these Terms and Conditions and all other rules, regulations and terms of use, Brainbucks may, at its sole and absolute discretion, take necessary remedial action, including but not limited to:<br />

                                1.  restricting, suspending, or terminating any User's access to all or any part of Brainbucks Services;<br />

                                2.  deactivating or deleting a User's account and all related information and files on the account. Any amount remaining unused in the User's Game account or Winnings Account on the date of deactivation or deletion shall be transferred to the User's bank account on record with Brainbucks subject to a processing fee (if any) applicable on such transfers as set out herein; or<br />

                                3.  refraining from awarding any prize(s) to such User.<br />

                                o    Users agree to provide true, accurate, current and complete information at the time of registration and at all other times (as required by Brainbucks). Users further agree to update and keep updated their registration information<br />

                                o    A User shall not register or operate more than one User account with Brainbucks.<br />

                                o    Users agree to ensure that they can receive all communication from Brainbucks by marking e-mails or sending SMSs from Brainbucks as part of their "safe senders" list. Brainbucks shall not be held liable if any e-mail/SMS remains unread by a User as a result of such e-mail getting delivered to the User's junk or spam folder.<br />

                                o    Any password issued by Brainbucks to a User may not be revealed to anyone else. Users may not use anyone else's password. Users are responsible for maintaining the confidentiality of their accounts and passwords. Users agree to immediately notify Brainbucks of any unauthorized use of their passwords or accounts or any other breach of security.<br />

                                o    Users agree to exit/log-out of their accounts at the end of each session. Brainbucks shall not be responsible for any loss or damage that may result if the User fails to comply with these requirements.<br />

                                o    Users agree not to use cheats, exploits, automation, software, bots, hacks or any unauthorised third party software designed to modify or interfere with Brainbucks Services and/or Brainbucks experience or assist in such activity.<br />

                                o    Users agree not to copy, modify, rent, lease, loan, sell, assign, distribute, reverse engineer, grant a security interest in, or otherwise transfer any right to the technology or software underlying Brainbucks or Brainbucks’s Services.<br />

                                o    Users agree that without Brainbucks's express written consent, they shall not modify or cause to be modified any files or software that are part of Brainbucks's Services.<br />

                                o    Users agree not to disrupt, overburden, or aid or assist in the disruption or overburdening of (a) any computer or server used to offer or support Brainbucks or the Brainbucks’s Services (each a "Server"); or (2) the enjoyment of Brainbucks Services by any other User or person.<br />

                                o    Users agree not to institute, assist or become involved in any type of attack, including without limitation to distribution of a virus, denial of service, or other attempts to disrupt Brainbucks Services or any other person's use or enjoyment of Brainbucks Services.<br />

                                o    Users shall not attempt to gain unauthorised access to the User accounts, Servers or networks connected to Brainbucks Services by any means other than the User interface provided by Brainbucks, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that underlies or is part of Brainbucks Services.<br />

                                o    User shall not to publish any content that is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person.<br />

                                o    Without limiting the foregoing, Users agree not to use Brainbucks for any of the following:<br />

                                1.  To engage in any obscene, offensive, indecent, racial, communal, anti-national, objectionable, defamatory or abusive action or communication;<br />

                                2.  To harass, stalk, threaten, or otherwise violate any legal rights of other individuals;<br />

                                3.  To publish, post, upload, e-mail, distribute, or disseminate (collectively, "Transmit") any inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful content;<br />

                                4.  To Transmit files that contain viruses, corrupted files, or any other similar software or programs that may damage or adversely affect the operation of another person's computer, Brainbucks, any software, hardware, or telecommunications equipment;<br />

                                5.  To advertise, offer or sell any goods or services for any commercial purpose on Brainbucks without the express written consent of Brainbucks;<br />

                                6.  To download any file, recompile or disassemble or otherwise affect our products that you know or reasonably should know cannot be legally obtained in such manner;<br />

                                7.  To falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or the source of software or other material;<br />

                                8.  To restrict or inhibit any other user from using and enjoying any public area within our sites;<br />

                                9.  To collect or store personal information about other Users;<br />

                                10.  To interfere with or disrupt Brainbucks, servers, or networks;<br />

                                11.  To impersonate any person or entity, including, but not limited to, a representative of Brainbucks, or falsely state or otherwise misrepresent User's affiliation with a person or entity;<br />

                                12.  To forge headers or manipulate identifiers or other data in order to disguise the origin of any content transmitted through Brainbucks or to manipulate User's presence on Brainbucks(s);<br />

                                13.  To take any action that imposes an unreasonably or disproportionately large load on our infrastructure;<br />

                                14.  To engage in any illegal activities. You agree to use our bulletin board services, chat areas, news groups, forums, communities and/or message or communication facilities (collectively, the "Forums") only to send and receive messages and material that are proper and related to that particular Forum.<br />

                                15.  To engage in any action that threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation.<br />

                                o    If a User chooses a username that, in Brainbucks's considered opinion is obscene, indecent, abusive or that might subject Brainbucks to public disparagement or scorn, or a name which is an official team/league/franchise names and/or name of any sporting personality, as the case may be, Brainbucks reserves the right, without prior notice to the User, to restrict usage of such names, which in Brainbucks’s opinion fall within any of the said categories and/or change such username and intimate the User or delete such username and posts from Brainbucks, deny such User access to Brainbucks, or any combination of these options.<br />

                                o    Unauthorized access to Brainbucks is a breach of these Terms and Conditions, and a violation of the law. Users agree not to access Brainbucks by any means other than through the interface that is provided by Brainbucks for use in accessing Brainbucks. Users agree not to use any automated means, including, without limitation, agents, robots, scripts, or spiders, to access, monitor, or copy any part of our sites, except those automated means that we have approved in advance and in writing.<br />

                                o    Use of Brainbucks is subject to existing laws and legal processes. Nothing contained in these Terms and Conditions shall limit Brainbucks's right to comply with governmental, court, and law-enforcement requests or requirements relating to Users' use of Brainbucks.<br />

                                o    Users may reach out to Brainbucks through -<br />

                                Helpdesk if the user has any concerns with regard to a Quiz and/or contest within Forty Eight (48) hours of winner declaration for the concerned contest.<br />

                                o    Persons below the age of eighteen (18) years are not allowed to participate on any of the contests, games (by whatever name called) on the Brainbucks Platform. The Users will have to disclose their real age at the time of getting access into the Brainbucks Platform.<br />

                                o    Brainbucks may not be held responsible for any content contributed by Users on the Brainbucks.<br />

                                o    User hereby confirms that he / she is participating in a contest in personal capacity and not in the course of business and /or profession.<br />
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>7.        Conditions of Participation:

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                By entering a Contest, user agrees to be bound by these Terms and the decisions of Brainbucks. Subject to the terms and conditions stipulated herein below, the Company, at its sole discretion, may disqualify any user from a Contest, refuse to award benefits or prizes and require the return of any prizes, if the user engages in unfair conduct, which the Company deems to be improper, unfair or otherwise adverse to the operation of the Contest or is in any way detrimental to other Users which includes, but is not limited to:<br />

                                o    Falsifying ones’ own personal information (including, but not limited to, name, email address, bank account details and/or any other information or documentation as may be requested by Brainbucks to enter a contest and/or claim a prize/winning.;<br />

                                o    Engaging in any type of financial fraud or misrepresentation including unauthorized use of credit/debit instruments, payment wallet accounts etc. to enter a Contest or claim a prize. It is expressly clarified that the onus to prove otherwise shall solely lie on the user.;<br />

                                o    Colluding with any other user(s) or engaging in any type of syndicate play;<br />

                                o    Any violation of Contest rules or the Terms of Use;<br />

                                o    Accumulating points or prizes through unauthorized methods such as automated bots, or other automated means;<br />

                                o    Using automated means (including but not limited to harvesting bots, robots, parser, spiders or screen scrapers) to obtain, collect or access any information on the Website or of any User for any purpose<br />

                                o    Any type of Cash Bonus misuse, misuse of the Invite Friends program, or misuse of any other offers or promotions;<br />

                                o    Tampering with the administration of a Contest or trying to in any way tamper with the computer programs or any security measure associated with a Contest;<br />

                                o    Obtaining other users’ information without their express consent and/or knowledge and/or spamming other users (Spamming may include but shall not be limited to send unsolicited emails to users, sending bulk emails to Brainbucks Users, sending unwarranted email content either to selected Users or in bulk); or<br />

                                o    Abusing the Website in any way (‘unparliamentary language, slangs or disrespectful words’ are some of the examples of Abuse)<br />

                                It is clarified that in case a User is found to be in violation of this policy, Brainbucks reserves its right to initiate appropriate Civil/Criminal remedies as it may be advised other than forfeiture and/or recovery of prize money if any.<br />
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>8.        Registration for a contest

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    In order to register for the Quiz(s), Participants are required to accurately provide the following information:

                                1.  Full Name<br />

                                2.  E-mail address<br />

                                3.  Contact Number<br />

                                4.  Password<br />

                                5.  State of Residence<br />

                                6.  Date of birth<br />

                                o    Participants are also required to confirm that they have read, and shall abide by, these Terms and Conditions.<br />

                                o    In the event a Participant indicates, while entering an address, that he/she is a resident of either Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana such Participant will not be permitted to proceed to sign up for any match in the paid version of the Contest as described below.<br />

                                o    Once the Participants have entered the above information, and clicked on the "register" tab, and such Participants are above the age of 18 years, they are sent an email confirming their registration and containing their login information.<br />
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>9.        Quiz(s), Participation and Prizes

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    As part of its services, Brainbucks may make available the Quiz(s) on the Brainbucks platform.<br />

                                o    Currently, following Quizes are made available on Brainbucks platform: 1) Central Indian Government Job Entrance Exam Related ( Central PSUs), 2) State Indian Government Job Entrance Exam Related ( State PSUs) Individual users wishing to participate in the such Quizzes ("“Participants"”) are invited to create their own profile on Brainbucks Platform , Brainbucks offers its platform to Participants for Quiz Quiz(s) being created relating to each Quiz Contest, and Participants can participate in such Quiz(s) by paying a Platform Fees ( if Applicable ).<br />



                                o    Rules for selecting a winner of the quiz and distributing the prize pool:<br />

                                Total of top 20% participants are considered as Winning group of a quiz, who receives the cash prize at the end of the quiz. Formula for calculating the Winners is:<br />

                                r = ( 20 * TP)/100<br />

                                r = Number of Winners<br />

                                TP = Total number of participants participating in the quiz.<br />

                                The winners are determined by the accuracy of the answers they give to the questions asked in the quiz, accuracy is determined by the following formula:<br />

                                A = (( Nc )/ Nq)*100<br />

                                Where,<br />

                                A = Accuracy<br />

                                Nc = Total number of questions answered correctly<br />

                                Nq = Total number of questions asked.<br />

                                For example: the total amount of players participating in a quiz are total 100, and the entry fees for each participant is 10 BB Coins, then the total prize pool sums up to 800 BB Coins, therefore this prize pool will be distributed among top 20% players that is to top 20 players in this case.<br />

                                Distribution of the prize among winners:<br />

                                The achiever with the highest amount of accuracy gets the biggest chunk of prize pool.<br />

                                The distribution of prize pool is determined by the following formula:<br />


                                pp = <code style={{ color: "black" }}>(r - R) + r*PP/Tp</code><br />





                                Where,<br />

                                pp = Prize to be received by winner.<br />

                                R = Rank of Winner<br />

                                r = Total number of winners<br />

                                PP = Total amount of prize pool<br />

                                Tp = Total Participants of the quiz<br />

                                For Example : If in the previous scenario the total number of Winners ( r ) are 20 and the amount of prize pool ( PP ) is 800 BB Coins and the Total Participants (Tp) are 100, then according to the above formula, winner with the rank 1, will get a Prize ( pp ) of 312 BB Coins and the winner with rank 2 will get a prize of 304 BB Coins and so on.<br />

                                In case of Tie:<br />

                                If the accuracy of 2 players comes same with the above mentioned formula, then the player completing quiz With lower Average Time Per Question (T) is selected on a higher rank, formula of calculating T is as follows :<br />

                                T = (t/q)<br />



                                Where,<br />



                                T = Average Time per Question<br />

                                t = Total time taken to complete the quiz in seconds<br />

                                q = Total number of questions asked in the quiz<br />



                                For example : If accuracy of 2 participants , let the name of participants be “A” and “B” came same in a quiz containing total 10 Questions but the total time taken by “A” to complete the quiz ( t ) was 20 Seconds and the total time taken by “B” to complete the quiz ( t ) was 25 Seconds, then Participant “A’s” Average Time Per Question ( T ) is 2 Seconds , according to the above mentioned formula, whereas Participant “B” is having Average Time per Question ( T ) is 2.5 Seconds, therefore according to the algorithm Participant A will be assigned a higher Rank than Participant B..<br />

                                o    The Quiz(s) across the Brainbucks Services shall, in addition to the Terms and Conditions, rules and regulations mentioned herein, be governed by:<br />

                                1.  "Rules & Regulations" available at  “Participation Rules” Section of our Brainbucks application<br />



                                §  Other rules and regulations (including rules and regulation in relation to any payments made to participate in the Quiz(s); and all Participants agree to abide by the same.<br />

                                o    Currently, there are paid versions of the Quiz(s) made available on Brainbucks platform. Users may participate in the Quiz(s) by paying the pre-designated amount as provided on the relevant Contest page. The ‘pre-designated amount’ means and includes pre-determined platform fee for accessing Brainbucks services and pre-determined participant’s contribution towards prize money pool. The Participant with the highest aggregate points at the end of the pre-determined match shall be eligible to win a pre-designated prize which is disbursed out of prize money pool, as stated on the relevant Quiz(s) page.<br />

                                o    A Participant may create different Teams for participation in Quiz(s) in relation to a Quiz Contest across the Brainbucks Services. However the number of participants is solely decided by Brainbucks and cannot be altered by any User.<br />

                                o    Participant shall pay a pre-designated amount for participating in the Quiz(s) being created on the Brainbucks platform.<br />
                                In the event a Participant indicates, while entering an address, that he/she is a resident of either Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana such Participant will not be permitted to proceed to sign up for the Quiz contest and may not participate in any paid version of the Quiz(s).<br />
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>10.     Contest Formats

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    Currently three formats of Quiz(s) are made available on Brainbucks platform (1) Paid Quizzes where Users can participate in a Contest with other Users without any restriction on participation and (2) Free Trivias, where Users can enter without paying any type of entry fees .(3) Rooms Contest, where user can create his/her own personalised rooms and can invite their friends or other members on the Brainbucks app to join the room and can organise their own desired Quiz contest, where they agree to abide all the rules and regulations of the Brainbucks platform. All rules applicable to Quiz(s) as set out herein shall be applicable to all formats of the Quiz(s). Users by participating in a Quiz(s) hereby authorize Brainbucks to appoint a third party/ Trustee/Escrow Agent to manage users funds on users behalf.<br />

                                o    Paid Quizzes<br />

                                1. In the Paid Quiz contest format of the Quiz(s), Brainbucks may make available the Quiz(s) comprising of 2 -– 100 Participants or any other pre-designated number of Participants.<br />

                                2.  Brainbucks may create this format of the Quiz(s) as a paid format and the Winner will be determinable at the end of the match as per rule of the contests.<br />

                                3.  The number of Participants required to make the Quiz(s) operational will be pre-specified and once the number of Participants in such Quiz(s) equals the pre-specified number required for that Quiz(s), such Quiz(s) shall be operational. In case the number of Participants is less than the pre-specified number at the time of commencement of the Quiz, such Quiz(s) will not be operational and the pre-designated amount paid by each Participant shall be returned to the account of such User without any charge or deduction.<br />

                                o    Free Trivias<br />

                                1.  In the Free Trivias format of the Quiz(s), Brainbucks enables Users to participate without paying any type of platform fees and can enter these contest at any point of time, the sole purpose of the Free Trivias is to enhance the user’s knowledge.
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>11.     Eligibility

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    The Quiz(s) are open only to persons above the age of 18 years.<br />

                                o    The Quiz(s) are open only to persons, currently residing in India.<br />

                                o    Brainbucks may, in accordance with the laws prevailing in certain Indian states, bar individuals residing in those states from participating in the Quiz(s). Currently, individuals residing in the Indian states of Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana may not participate in the paid version of the Contest as the laws of these states are unclear/ bar persons from participating in Quizzes of skill where participants are required to pay to enter.<br />

                                o    Persons who wish to participate must have a valid email address.<br />

                                o    Only those Participants who have successfully registered on the Brainbucks as well as registered prior to each Quiz in accordance with the procedure outlined above shall be eligible to participate in the Quiz Contest and win prizes.<br />
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>12.     Payment Terms

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    In respect of any transactions entered into on the Brainbucks platform, including making a payment to participate in the paid versions of Quiz(s), Users agree to be bound by the following payment terms:<br />

                                1.  The payment of pre-designated amount Users make to participate in the Quiz(s) is inclusive of the pre-designated platform fee for access to the Brainbucks Services charged by Brainbucks and pre-determined participant’s contribution towards prize money pool.<br />

                                2.  Subject to these Terms and Conditions, all amounts collected from the User are held in a separate non-interest earning bank Accounts. The said accounts are operated by a third party appointed by Brainbucks in accordance with Clause 10 of these Terms and Conditions. From these bank accounts, the payouts can be made to (a) Users (towards their withdrawals), (b) Brainbucks (towards its Platform Fees) and to (c) Government (towards TDS on Winnings Amount). Brainbucks receives only its share of the platform Fees through the said Escrow Agent.<br />

                                3.  The Brainbucks reserves the right to charge a Platform Fee, which would be specified and notified by Brainbucks on the Contest page, being created on Brainbucks platform, prior to a User's joining of such Contest. The Platform Fee (inclusive of applicable tax thereon) will be debited from the User’s account balance and Brainbucks shall issue an invoice for such debit to the User.<br />

                                4.  The User may participate in a Contest wherein the User has to contribute a pre-specified contribution towards the Prize Money Pool of such Contest, which will be passed on to the Winner(s) of the Contest after the completion of the Contest as per the terms and conditions of such Contest. It is clarified that Brainbucks has no right or interest in this Prize Money Pool, and only acts as an intermediary engaged in collecting and distributing the Prize Money Pool in accordance with the Contest terms and conditions. The amount to be paid-in by the User towards the Prize Money Pool would also be debited from the User’s account balance maintained with the Trustee.<br />

                                5.  Any user availing Brainbucks services are provided with two categories of accounts for the processing and reconciliation of payments: (i) 'Unutilized' Account, (ii) Winnings Account. It is clarified that in no instance the transfer of any amounts in the User's accounts to any other category of account held by the user or any third party account, including a bank account held by a third party:<br />

                                (a)        User's winnings in any Contest will reflect as credits to the User's Winnings Account.<br />

                                (b)        User’s remitting the amount through the designated payment gateway shall be credited to User’s Unutilized Account’.<br />

                                6.  Each time a User participates in any contest on Brainbucks platform, the pre-designated amount shall be debited in the User’s account. In debiting amounts from the User’s accounts towards the pre-designated amount of such user shall be debited from the User’s Unutilized Account and thereafter, any remaining amount of participation fee shall be debited from the User’s Winning Account.<br />

                                7.  In case there is any amount remaining to be paid by the User in relation to such User’s participation in any match(s) or Quiz(s), the User will be taken to the designated payment gateway to give effect to such payment. In case any amount added by the User through such payment gateway exceeds the remaining amount of the pre-designated amount, the amount in excess shall be transferred to the User’s ‘Unutilized’ Account and will be available for use in participation in any match(s) or Quiz(s).<br />

                                8.  Debits from the ‘Unutilized’ Account for the purpose of enabling a user’s participation in a Contest shall be made in order of the date of credit of amounts in the ‘Unutilized’ Account, and accordingly amounts credited into ‘Unutilized’ Account earlier in time shall be debited first.<br />

                                9.  All amounts credited into a User's 'Unutilized' Account must be utilised within 335 days of credit. In case any unutilised amount lies in the 'Unutilized' Account after the completion of 335 days from the date of credit of such amount, Brainbucks reserves the right to forfeit such unutilised amount, without liability or obligation to pay any compensation to the User.<br />

                                10.  Withdrawal of any amount standing to the User's credit in the Winnings Account may be made by way of a request to Brainbucks. Brainbucks shall effect an online transfer to the User's bank account on record with Brainbucks within a commercially reasonable period of time. Such transfer will reflect as a debit to the User's Winnings Account. Brainbucks shall not charge any processing fee for the online transfer of such amount from the Winnings Account to the User's bank account on record with Brainbucks. Users are requested to note that they will be required to provide valid photo identification and address proof documents for proof of identity and address in order for Brainbucks to process the withdrawal request. The name mentioned on the User's photo identification document should correspond with the name provided by the User at the time of registration on Brainbucks, as well as the name and address existing in the records of the User's bank account as provided to Brainbucks. In the event that no bank account has been registered by the User against such User's account with Brainbucks, or the User has not verified his/her User account with Brainbucks, to Brainbucks's satisfaction and in accordance with these Terms and Conditions, and in case the User fails to register a bank account with his/her User Account and/or to verify his/her User Account, Brainbucks shall not transfer any Winning amounts to the User.<br />

                                11.  Further, in order to conduct promotional activities, Brainbucks may gratuitously issue Cash Bonus to the User for the purpose of participation in any Quiz(s) and no User shall be permitted to transfer or request the transfer of any amount in to the Cash Bonus. The usage of any Cash Bonus issued shall be subject to the limitations and restrictions, including without limitation, restrictions as to time within which such Cash Bonus must be used, as applied by Brainbucks and notified to the User at the time of issue of such amount. The issue of any Cash Bonus to the user is subject to the sole discretion of Brainbucks and cannot be demanded by any User as a matter of right. The issue of any Cash Bonus by Brainbucks on any day shall not entitle the user to demand the issuance of such Cash Bonus at any subsequent period in time nor create an expectation of recurring issue of such Cash Bonus by Brainbucks to such User. The Cash Bonus granted to the user may be used by such User for the purpose of setting off against the contribution to prize pool in any Contest, in accordance with these Terms and Conditions. The Cash Bonus shall not be withdraw-able or transferrable to any other account of the User, including the bank account of such User, or of any other User or person, other that as part of the winnings of a User in any Quiz(s). In case the User terminates his/her account with Brainbucks or such account if terminated by Brainbucks, all Cash Bonus granted to the user shall return to Brainbucks and the User shall not have any right or interest on such points.<br />

                                12.  All Cash Bonus credited in the User account shall be valid for a period of 14 days from the date of credit. The unutilized Cash Bonus shall expire at the end of 14 days from the date of credit.<br />

                                13.  Users agree that once they confirm a transaction on Brainbucks, they shall be bound by and make payment for that transaction.<br />

                                14.  The User acknowledges that subject to time taken for bank reconciliations and such other external dependencies that Brainbucks has on third parties, any transactions on Brainbucks Platform may take up to 24 hours to be processed. Any amount paid or transferred into the User's 'Unutilized' Account or Winnings Account may take up to 24 hours to reflect in the User's 'Unutilized' Account or Winnings Account balance. Similarly, the utilization of the Cash Bonus or money debited from 'Unutilized' Account or Winnings Account may take up to 24 hours to reflect in the User's 'Unutilized' Account or Winnings Account balance. Users agree not to raise any complaint or claim against Brainbucks in respect of any delay, including any lost opportunity to join any Contest or match due to delay in crediting of transaction amount into any of the User's accounts<br />
                                A transaction, once confirmed, is final and no cancellation is permissible.<br />
                                Brainbucks may, in certain exceptional circumstances and at its sole and absolute discretion, refund the amount to the User after deducting applicable cancellation charges and taxes. At the time of the transaction, Users may also be required to take note of certain additional terms and conditions and such additional terms and conditions shall also govern the transaction. To the extent that the additional terms and conditions contain any clause that is conflicting with the present terms and conditions, the additional terms and conditions shall prevail.<br />
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>13.     Selection and Verification of Winners and Conditions relating to the Prizes

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                Selection of Winners<br />
                                Total of top 20% participants are considered as Winning group of a quiz, who receives the cash prize at the end of the quiz. Formula for calculating the Winners is:<br />

                                r = ( 20 * TP)/100<br />

                                r = Number of Winners<br />

                                TP = Total number of participants participating in the quiz.<br />

                                The winners are determined by the accuracy of the answers they give to the questions asked in the quiz, accuracy is determined by the following formula:<br />

                                A = (( Nc )/ Nq)*100<br />

                                Where,<br />

                                A = Accuracy<br />

                                Nc = Total number of questions answered correctly<br />

                                Nq = Total number of questions asked.<br />

                                For example: the total amount of players participating in a quiz are total 100, and the entry fees for each participant is 10 BB Coins, then the total prize pool sums up to 800 BB Coins, therefore this prize pool will be distributed among top 20% players that is to top 20 players in this case.<br />

                                Distribution of the prize among winners:<br />

                                The achiever with the highest amount of accuracy gets the biggest chunk of prize pool.<br />

                                The distribution of prize pool is determined by the following formula:<br />

                                pp = <code style={{ color: "black" }}>(r - R) + r*PP/Tp</code><br />

                                Where,<br />

                                pp = Prize to be received by winner.<br />

                                R = Rank of Winner<br />

                                r = Total number of winners<br />

                                PP = Total amount of prize pool<br />

                                Tp = Total Participants of the quiz<br />

                                For Example : If in the previous scenario the total number of Winners ( r ) are 20 and the amount of prize pool ( PP ) is 800 BB Coins and the Total Participants (Tp) are 100, then according to the above formula, winner with the rank 1, will get a Prize ( pp ) of 312 BB Coins and the winner with rank 2 will get a prize of 304 BB Coins and so on.<br />

                                In case of Tie:<br />

                                If the accuracy of 2 players comes same with the above mentioned formula, then the player completing quiz With lower Average Time Per Question (T) is selected on a higher rank, formula of calculating T is as follows :<br />

                                T = (t/q)<br />



                                Where,<br />



                                T = Average Time per Question<br />

                                t = Total time taken to complete the quiz in seconds<br />

                                q = Total number of questions asked in the quiz<br />



                                For example : If accuracy of 2 participants , let the name of participants be “A” and “B” came same in a quiz containing total 10 Questions but the total time taken by “A” to complete the quiz ( t ) was 20 Seconds and the total time taken by “B” to complete the quiz ( t ) was 25 Seconds, then Participant “A’s” Average Time Per Question ( T ) is 2 Seconds , according to the above mentioned formula, whereas Participant “B” is having Average Time per Question ( T ) is 2.5 Seconds, therefore according to the algorithm Participant A will be assigned a higher Rank than Participant B..<br />

                                o    Contacting Winners<br />
                                Winners shall be contacted by Brainbucks or the third party conducting the Contest on the e-mail address provided at the time of registration. The verification process and the documents required for the collection of prize shall be detailed to the Winners at this stage. As a general practice, winners will be required to provide following documents:<br />

                                §  Photocopy of the User's PAN card;<br />

                                §  Photocopy of a government-issued residence proof;<br />

                                §  User's bank account details and proof of the same.<br />

                                Brainbucks shall not permit a Winner to withdraw his/her prize(s)/accumulated winnings unless the above-mentioned documents have been received and verified within the time-period stipulated by Brainbucks. The User represents and warrants that the documents provided in the course of the verification process are true copies of the original documents to which they relate.<br />

                                Participants are required to provide proper and complete details at the time of registration. Brainbucks shall not be responsible for communications errors, commissions or omissions including those of the Participants due to which the results may not be communicated to the Winner.<br />

                                The list of Winners shall be posted on a separate web-page on the Brainbucks. The winners will also be intimated by e-mail.<br />

                                In the event that a Participant has been declared a Winner on the abovementioned web-page but has not received any communication from Brainbucks, such Participant may contact Brainbucks within the time specified on the webpage.<br />

                                o    Verification process<br />
                                Only those Winners who successfully complete the verification process and provide the required documents within the time limit specified by Brainbucks shall be permitted to withdraw/receive their accumulated winnings (or any part thereof). Brainbucks shall not entertain any claims or requests for extension of time for submission of documents.<br />
                                Brainbucks shall scrutinize all documents submitted and may, at its sole and absolute discretion, disqualify any Winner from withdrawing his accumulated winnings (or any part thereof) on the following matchs:<br />

                                §  Determination by Brainbucks that any document or information submitted by the Participant is incorrect, misleading, false, fabricated, incomplete or illegible; or<br />

                                §  Participant does not fulfill the Eligibility Criteria specified in Clause 10 above; or<br />

                                §  Any other match.<br />

                                o    Taxes Payable<br />
                                All prizes shall be subject to deduction of tax (“TDS”) as per the Income Tax Act 1961. The TDS rate prescribed by the Government of India with respect to any prize money amount that is in excess of Rs. 10,000/- is 30% . TDS of 30% shall be deducted on Net Winnings ( prize money amount - contest entry amount - cash bonus). In case of any revisions by the Government of India to the aforementioned rate in the future, TDS will be deducted by Brainbucks in accordance with the then current prescribed TDS rate. Winners will be provided TDS certificates in respect of such tax deductions. The Winners shall be responsible for payment of any other applicable taxes, including but not limited to, income tax, gift tax, etc. in respect of the prize money.<br />

                                o    Miscellaneous<br />
                                The decision of Brainbucks with respect to the awarding of prizes shall be final, binding and non-contestable.<br />

                                Participants playing the paid formats of the Quiz(s) confirm that they are not residents of any of the following Indian states - Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana. If it is found that a Participant playing the paid formats of the Quiz(s) is a resident of any of the abovementioned states, Brainbucks shall disqualify such Participant and forfeit any prize won by such Participant. Further Brainbucks may, at its sole and absolute discretion, suspend or terminate such Participant's account with Brainbucks. Any amount remaining unused in the User's Game Account or Winnings Account on the date of deactivation or deletion shall be reimbursed to the User by an online transfer to the User's bank account on record with Brainbucks, subject to the processing fee (if any) applicable on such transfers as set out herein.<br />

                                If it is found that a Participant playing the paid formats of the Quiz(s) is under the age of eighteen (18), Brainbucks shall be entitled, at its sole and absolute discretion, to disqualify such Participant and forfeit his/her prize. Further, Brainbucks may, at its sole and absolute discretion, suspend or terminate such Participant's account.<br />

                                To the extent permitted by law, Brainbucks makes no representations or warranties as to the quality, suitability or merchantability of any prizes and shall not be liable in respect of the same.<br />

                                Brainbucks may, at its sole and absolute discretion, vary or modify the prizes being offered to winners. Participants shall not raise any claim against Brainbucks or question its right to modify such prizes being offered, prior to closure of the Contest.<br />

                                Brainbucks will not bear any responsibility for the transportation or packaging of prizes to the respective winners. Brainbucks shall not be held liable for any loss or damage caused to any prizes at the time of such transportation.<br />

                                The Winners shall bear the shipping, courier or any other delivery cost in respect of the prizes.<br />

                                The Winners shall bear all transaction charges levied for delivery of cash prizes.<br />

                                All prizes are non-transferable and non-refundable. Prizes cannot be exchanged / redeemed for cash or kind. No cash claims can be made in lieu of prizes in kind.<br />
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>14.     Publicity

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                Acceptance of a prize by the Winner constitutes permission for Brainbucks, and its affiliates to use the Winner's name, likeness, voice and comments for advertising and promotional purposes in any media worldwide for purposes of advertising and trade without any further permissions or consents and / or additional compensation whatsoever.<br />
                                The Winners further undertake that they will be available for promotional purposes as planned and desired by Brainbucks without any charge. The exact dates remain the sole discretion of Brainbucks. Promotional activities may include but not be limited to press events, internal meetings and ceremonies/functions.
                            </span>
                        </div>

                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>15.     General Conditions

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                If it comes to the notice of Brainbucks that any governmental, statutory or regulatory compliances or approvals are required for conducting any Quiz(s) or if it comes to the notice of Brainbucks that conduct of any such Quiz(s) is prohibited, then Brainbucks shall withdraw and / or cancel such Quiz(s) without prior notice to any Participants or winners of any Quiz(s). Users agree not to make any claim in respect of such cancellation or withdrawal of the Contest or Contest it in any manner.<br />

                                Employees, directors, affiliates, relatives and family members of Brainbucks, will not be eligible to participate in any Quiz(s).
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>16.     Dispute and Dispute Resolution

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    The courts of competent jurisdiction at Jaipur shall have exclusive jurisdiction to determine any and all disputes arising out of, or in connection with, the Brainbucks Services provided by Brainbucks (including the Quiz(s)), the construction, validity, interpretation and enforceability of these Terms and Conditions, or the rights and obligations of the User(s) (including Participants) or Brainbucks, as well as the exclusive jurisdiction to grant interim or preliminary relief in case of any dispute referred to arbitration as given below. All such issues and questions shall be governed and construed in accordance with the laws of the Republic of India.<br />

                                o    In the event of any legal dispute (which may be a legal issue or question) which may arise, the party raising the dispute shall provide a written notification ("Notification") to the other party. On receipt of Notification, the parties shall first try to resolve the dispute through discussions. In the event that the parties are unable to resolve the dispute within fifteen (15) days of receipt of Notification, the dispute shall be settled by arbitration.<br />

                                o    The place of arbitration shall be Jaipur, India. All arbitration proceedings shall be conducted in English and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time.<br />

                                o    The arbitration award will be final and binding on the Parties, and each Party will bear its own costs of arbitration and equally share the fees of the arbitrator unless the arbitral tribunal decides otherwise. The arbitrator shall be entitled to pass interim orders and awards, including the orders for specific performance and such orders would be enforceable in competent courts. The arbitrator shall give a reasoned award.<br />

                                o    Nothing contained in these Terms and Conditions shall prevent Brainbucks from seeking and obtaining interim or permanent equitable or injunctive relief, or any other relief available to safeguard Brainbucks's interest prior to, during or following the filing of arbitration proceedings or pending the execution of a decision or award in connection with any arbitration proceedings from any court having jurisdiction to grant the same. The pursuit of equitable or injunctive relief shall not constitute a waiver on the part of Brainbucks to pursue any remedy for monetary damages through the arbitration described herein.
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>17.     Release and Limitations of Liability

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    Users shall access the Brainbucks Services provided on Brainbucks voluntarily and at their own risk. Brainbucks shall, under no circumstances be held responsible or liable on account of any loss or damage sustained (including but not limited to any accident, injury, death, loss of property) by Users or any other person or entity during the course of access to the Brainbucks Services (including participation in the Quiz(s)) or as a result of acceptance of any prize.<br />

                                o    By entering the contests and accessing the Brainbucks Services provided therein, Users hereby release from and agree to indemnify Brainbucks, and/ or any of its directors, employees, partners, associates and licensors, from and against all liability, cost, loss or expense arising out their access to the Brainbucks Services including (but not limited to) personal injury and damage to property and whether direct, indirect, consequential, foreseeable, due to some negligent act or omission on their part, or otherwise.<br />

                                o    Brainbucks accepts no liability, whether jointly or severally, for any errors or omissions, whether on behalf of itself or third parties in relation to the prizes.<br />

                                o    Users shall be solely responsible for any consequences which may arise due to their access of Brainbucks Services by conducting an illegal act or due to non-conformity with these Terms and Conditions and other rules and regulations in relation to Brainbucks Services, including provision of incorrect address or other personal details. Users also undertake to indemnify Brainbucks and their respective officers, directors, employees and agents on the happening of such an event (including without limitation cost of attorney, legal charges etc.) on full indemnity basis for any loss/damage suffered by Brainbucks on account of such act on the part of the Users.<br />

                                o    Users shall indemnify, defend, and hold Brainbucks harmless from any third party/entity/organization claims arising from or related to such User's engagement with the Brainbucks or participation in any Contest. In no event shall Brainbucks be liable to any User for acts or omissions arising out of or related to User's engagement with the Brainbucks or his/her participation in any Quiz(s).<br />

                                o    In consideration of Brainbucks allowing Users to access the Brainbucks Services, to the maximum extent permitted by law, the Users waive and release each and every right or claim, all actions, causes of actions (present or future) each of them has or may have against Brainbucks, its respective agents, directors, officers, business associates, group companies, sponsors, employees, or representatives for all and any injuries, accidents, or mishaps (whether known or unknown) or (whether anticipated or unanticipated) arising out of the provision of Brainbucks Services or related to the Contests or the prizes of the Contests.<br />
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>18.     Disclaimers

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    To the extent permitted under law, neither Brainbucks nor its parent/holding company, subsidiaries, affiliates, directors, officers, professional advisors, employees shall be responsible for the deletion, the failure to store, the mis-delivery, or the untimely delivery of any information or material.<br />

                                o    To the extent permitted under law, Brainbucks shall not be responsible for any harm resulting from downloading or accessing any information or material, the quality of servers, games, products, Brainbucks services or sites, cancellation of competition and prizes. Brainbucks disclaims any responsibility for, and if a User pays for access to one of Brainbucks's Services the User will not be entitled to a refund as a result of, any inaccessibility that is caused by Brainbucks's maintenance on the servers or the technology that underlies our sites, failures of Brainbucks's service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of our facilities, acts of nature, war, civil disturbance, or any other cause beyond our reasonable control. In addition, Brainbucks does not provide any warranty as to the content on the Brainbucks(s). Brainbucks(s) content is distributed on an "as is, as available" basis.<br />

                                o    Any material accessed, downloaded or otherwise obtained through Brainbucks is done at the User's discretion, competence, acceptance and risk, and the User will be solely responsible for any potential damage to User's computer system or loss of data that results from a User's download of any such material.<br />

                                o    Brainbucks shall make best endeavours to ensure that the Brainbucks(s) is error-free and secure, however, neither Brainbucks nor any of its partners, licensors or associates makes any warranty that:<br />

                                1.  the Brainbucks(s) will meet Users' requirements,<br />

                                2.  Brainbucks(s) will be uninterrupted, timely, secure, or error free<br />

                                3.  the results that may be obtained from the use of Brainbucks(s) will be accurate or reliable; and<br />

                                4.  the quality of any products, Brainbucks Services, information, or other material that Users purchase or obtain through Brainbuckscom(s) will meet Users' expectations.<br />

                                o    In case Brainbucks discovers any error, including any error in the determination of Winners or in the transfer of amounts to a User's account, Brainbucks reserves the right (exercisable at its discretion) to rectify the error in such manner as it deems fit, including through a set-off of the erroneous payment from amounts due to the User or deduction from the User's account of the amount of erroneous payment. In case of exercise of remedies in accordance with this clause, Brainbucks agrees to notify the User of the error and of the exercise of the remedy(ies) to rectify the same.<br />

                                o    To the extent permitted under law, neither Brainbucks nor its partners, licensors or associates shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use our sites, even if we have been advised of the possibility of such damages.<br />

                                o    Any Brainbucks Services, events or Quiz(s) being hosted or provided, or intended to be hosted on Brainbucks platform and requiring specific permission or authority from any statutory authority or any state or the central government, or the board of directors shall be deemed cancelled or terminated, if such permission or authority is either not obtained or denied either before or after the availability of the relevant Brainbucks Services, events or Quiz(s) are hosted or provided.<br />

                                o    To the extent permitted under law, in the event of suspension or closure of any Services, events or Contests, Users (including Participants) shall not be entitled to make any demands, claims, on any nature whatsoever.<br />
                            </span>
                        </div>

                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>19.     Miscellaneous

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    Brainbucks may be required under certain legislations, to notify User(s) of certain events. User(s) hereby acknowledge and consent that such notices will be effective upon Brainbucks posting them on the Brainbucks or delivering them to the User through the email address provided by the User at the time of registration. User(s) may update their email address by logging into their account on the Brainbucks. If they do not provide Brainbucks with accurate information, Brainbucks cannot be held liable for failure to notify the User.<br />

                                o    Brainbucks's failure to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.<br />

                                o    Users agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Brainbucks or these Terms must be filed within thirty (30) days of such claim or cause of action arising or be forever barred.<br />

                                o    These Terms and Conditions, including all terms, conditions, and policies that are incorporated herein by reference, constitute the entire agreement between the User(s) and Tech Brainbucks Infosoft Private Limited and govern your use of the Brainbucks, superseding any prior agreements that any User may have with Tech Brainbucks Infosoft Private Limited.<br />

                                o    If any part of these Terms and Conditions is determined to be indefinite, invalid, or otherwise unenforceable, the rest of these Terms and Conditions shall continue in full force.<br />

                                o    Brainbucks reserves the right to moderate, restrict or ban the use of the Brainbucks, specifically to any User, or generally, in accordance with Brainbucks's policy/policies from time to time, at its sole and absolute discretion and without any notice.<br />

                                o    Brainbucks may, at its sole and absolute discretion, permanently close or temporarily suspend any Brainbucks Services (including any Quiz(s)).<br />

                                o    Brainbucks may from time to time conduct/organize, promotions/offers on the platform. Any two or more promotions cannot be clubbed together with any other promotions that are running simultaneously on the Brainbucks platform. Also, promotions/offers cannot be clubbed with Cash Bonus available with any user.
                            </span>
                        </div>


                        <div className='mt-2' style={{}}>

                            <p style={{ fontWeight: "bold" }}>20.     Grievance Redressal Mechanism

                            </p>
                            <span style={{ fontSize: "13px", lineHeight: "1px", fontFamily: "arial" }} >
                                o    In case you have any complaints or grievance pertaining to (i) any User Content that you believe violates these Terms (other than an infringement of Intellectual Property Rights) for the reporting mechanism for such claims), (ii) your access to the Platform or (iii) any User Content which you believe is, prima facie, in the nature of any material which is obscene, defamatory towards the complainant or any person on whose behalf you are making the complaint, or is in the nature of impersonation in an electronic form, including artificially morphed images of such individual, please share the same with us by writing to: grievanceofficer@brainbucks.in<br />

                                o    In your complaint or grievance, please include the following information:<br />

                                1.  Your name and contact details: name, address, contact number and email address;<br />

                                2.  Your relation to the subject matter of the complaint, i.e. complainant or person acting on behalf of an affected person;<br />

                                3.  The name and age of the person aggrieved or affected by the subject matter of the complaint, in case your are acting on behalf of such person and a statement, under penalty of perjury, that you are authorised to act on behalf of such person and to provide such person's personal information to us in relation to the complaint/grievance;<br />

                                4.  Description of the complaint or grievance with clear identification of the User Content in relation to which such complaint or grievance is made;<br />

                                5.  A statement that you believe, in good faith, that the User Content violates these Terms<br />

                                6.  A statement that the information provided in the complaint or grievance is accurate.<br />

                                o    The Company respects the Intellectual Property Rights of others. All names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on the Platform belonging to any person (including User), entity or third party are recognized as proprietary to the respective owners. You are requested to send us a written notice/ intimation if you notice any act of infringement on the Platform, which must include the following information:<br />

                                1.  A clear identification of the copyrighted work allegedly infringed;<br />

                                2.  A clear identification of the allegedly infringing material on the Platform;<br />

                                3.  Your contact details: name, address, e-mail address and phone number;<br />

                                4.  A statement that you believe, in good faith, that the use of the copyrighted material allegedly infringed on the Platform is not authorized by your agent or the law;<br />

                                5.  A statement that the information provided in the notice is accurate, and under penalty of perjury, that the signatory is authorized to act on behalf of the owner of an exclusive copyright right that is allegedly infringed;<br />

                                6.  Your signature or a signature of your authorized agent.<br />

                                The aforesaid notices can be sent to the Company by email at grievanceofficer@brainbucks.in.<br />

                                o    On receiving such complaint, grievance or notice, the Company reserves the right to investigate and/or take such action as the Company may deem appropriate. The Company may reach out to you to seek further clarification or assistance with the investigation, or verify the statements made in your complaint, grievance or notice, and you acknowledge that your timely assistance with the investigation would facilitate the redressal of the same.<br />

                                o    The name and title of the Grievance Redressal Officer is as follows:<br />

                                Name: Pooja Saxena<br />

                                Email: grievanceofficer@brainbucks.in<br />

                                Address: Tech Brainbucks Infosoft Private Limited, 101-103 CHANDA TOWER MAIN GANDHI PATH W OPP. GRAND UTSAV GARDEN, VAISHALI NAGAR JAIPUR Jaipur RJ 302021 INDIA. The Grievance Officer identified above pursuant to the provisions of applicable laws including but not limited to the Information Technology Act, 2000 and the Consumer Protection Act, 2019, and the Rules enacted under those laws.<br />

                                The Company reserves the right to replace the Grievance Redressal Officer at its discretion through publication of the name and title of such replacement on the website, which replacement shall come into effect immediately upon publication.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsandConditions
