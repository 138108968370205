import React, { useState } from 'react';
import '../DeleteAccount/DeleteAcc.css';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeleteFormAccount = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [reason, setReason] = useState('');
  const [customReason, setCustomReason] = useState('');
  const navigate = useNavigate();

  const validateForm = () => {
    if (!name || !phone || !reason) {
      alert('Please fill in all fields');
      return false;
    }
    if (!/^\+91[0-9]{10}$/.test(phone)) {
      toast.error('Please enter a valid 10-digit mobile number with country code +91');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Ensure preventDefault is called correctly
  
    if (!validateForm()) {
      return;
    }
  
    try {
      const response = await fetch('https://auth.brainbucks.in/auth/participant/delete/account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          phone,
          reason: reason === 'other' ? customReason : reason,
        }),
      });
  
      const result = await response.json(); // Await the JSON parsing
  
      if (response.ok && result.status === 1) {
        toast.success(result.msg);
        setTimeout(() => {
          navigate('/');
        }, 1000); // Adding a delay before navigation to allow toast to be shown
      } else {
        toast.error(result.msg || 'Failed to delete');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      toast.error(error.message || 'An error occurred while deleting the account');
    }
  };

  
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleMobileChange = (e) => {
    setPhone(e.target.value);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleCustomReasonChange = (e) => {
    setCustomReason(e.target.value);
  };

  const reasons = [
    { value: '', label: 'Select a reason' },
    { value: 'not_using', label: 'Not using the service' },
    { value: 'Not_found_Relevant', label: 'Not Found Relevant to me' },
    { value: 'found_alternative', label: 'Found an alternative' },
    { value: 'other', label: 'Other' }
  ];

  return (
    <>
      <ToastContainer />
      <div className='container p-4'>
        <div className="delete-account-form-container">
          <h3 className='text-center'>Delete Account</h3>
          <form onSubmit={handleSubmit}>
            {/* Name input field */}
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={handleNameChange}
                placeholder="Enter your name"
                className="form-control"
                required
              />
            </div>
            {/* Mobile number input field */}
            <div className="form-group">
              <label htmlFor="phone">Mobile Number: </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={phone}
                onChange={handleMobileChange}
                placeholder="Enter Mobile Number"
                className="form-control"
                maxLength={13}
                required
              />
            </div>
            {/* Reason dropdown */}
            <div className="form-group">
              <label htmlFor="reason">Select Reason:</label>
              <select
                id="reason"
                name="reason"
                value={reason}
                onChange={handleReasonChange}
                className="form-control"
                required
              >
                {reasons.map((reason, index) => (
                  <option key={index} value={reason.value}>{reason.label}</option>
                ))}
              </select>
            </div>
            {/* Custom reason textarea */}
            {reason === 'other' && (
              <div className="form-group">
                <label htmlFor="customReason">Other Reason:</label>
                <textarea
                  id="customReason"
                  name="customReason"
                  value={customReason}
                  onChange={handleCustomReasonChange}
                  placeholder="Enter Other Reason"
                  className="form-control"
                  rows="3"
                />
              </div>
            )}
            {/* Submit button */}
            <button className="btn btn-primary" >Delete Account</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default DeleteFormAccount;
