import React, { useEffect, useState } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import '../blogs/blog.css'

import logo from '../assets/logo.png'
import { IMAGE_URL, PRERECMICRO, QUIZMICRO } from "../config/url";
import { CiSearch } from 'react-icons/ci';
import { Carousel } from 'react-bootstrap'

import { MdOutlineCalendarMonth } from 'react-icons/md'
import { GoArrowRight } from 'react-icons/go'
import { Navigate, useNavigate } from 'react-router-dom'
import { useSearch } from '../ContextData/SearchContext'


const Blog = () => {
    const Navigate = useNavigate();
    const page = 1; 
    const limit = 10;
    const query = ('');
    const [blog, setblog] = useState([])
    const [category, setcategory] = useState([])
    const [emptyId] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

    const { searchBlogData, setSearchBlogData } = useSearch();
    

    const handleSearchChange = (e) => {
        setSearchBlogData(e.target.value);
        
      };
   console.log("sfs",searchBlogData);

    const latest_blog = async () => {
        try {
            const requestOptions = {
                method: "GET",
                redirect: "follow"
            };

            const response = await fetch(`${PRERECMICRO}/learning/page/get/latest/blog?page=${page}&limit=${limit}`, requestOptions)
            const result = await response.json()
            if (result.status == 1) {
                setblog(result.blogs)
            }
        } catch (error) {
            console.log(error)
        }
    }
    // const searchBlogs = async (query) => {
    //     try {
    //         const requestOptions = {
    //             method: "GET",
    //             redirect: "follow"
    //         };
    
    //         // Append the query parameter to the URL
    //         const url = `${PRERECMICRO}/learning/page/get/blog?search=${encodeURIComponent(query)}&page=${page}&limit=${limit}`;
    
    //         const response = await fetch(url, requestOptions);
    //         const result = await response.json();
    //         if (result.status === 1) {
    //             setSearchedBlogs(result.blogs);
    //         } else {
    //             console.log("No results found");
    //             setSearchedBlogs([]);
    //         }
    //     } catch (error) {
    //         console.log("Error fetching search results:", error);
    //     }
    // }
    
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 500);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    
      const sliceSize = isMobile ? 1 : 3;
    const view_category = async () => {
        try {
            const requestOptions = {
                method: "GET",
                redirect: "follow"
            };

            const response = await fetch(`${QUIZMICRO}/formfill/get/category?search=`, requestOptions)
            const result = await response.json()
            if (result.status == 1) {
                setcategory(result?.categories)
            }
        } catch (error) {
            console.log(error)
        }
    }



    useEffect(() => {
        latest_blog(1, 10)
        view_category()
      
    }, [])

    return (
        <div>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                {/* Navbar */}
                <div style={{ height: "7vh" }}>
                    <Header />
                </div>

                {/* Main Content */}
                <div className='border' style={{}}>
                    <div className='container'>
                        <div className='row mt-5'>
                            <div className='col-12'>

                                <div className='text-center mt-3'>
                                    <h1 style={{ fontWeight: "700" }}>Blogs</h1>
                                    <p className='' style={{ fontWeight: "600", color: "grey" }}>Read our Daily Updated blogs to keep yourself acknowledged and<br />
                                        updated about the latest happening around the globe.</p>
                                </div>

                                <div className='row '>
                                    <div className='col-lg-4 col-sm-12  m-auto mt-4  '>

                                        <div class="input-group mb-3  d-flex gap-1  justify-content-center  inp">
                                            <CiSearch className='mt-2 ms-1' style={{ fontSize: "20px" }} />

                                            <input style={{ border: "none" }}  
                                            type="text" class="form-control "  
                                            placeholder="Search for a Blog" 
                                            aria-label="serach for a blog" 
                                            aria-describedby="button-addon2" 
                                            value={searchBlogData}
                                            onChange={handleSearchChange}
                                            />

                                            <button class="btn btn-dark" type="button" onClick={() => { Navigate(`/view/all/blog/${emptyId}`) }}>Search</button>
                                        </div>

                                    </div>
                                </div>

                                <div className='row  pt-4'>
                                    <h2 className='text-center'>Browse by Categories</h2>
                                </div>




                                <div className='row mt-5 d-flex gap-1  justify-content-center'>
                                    {
                                        category?.map((res) => {
                                            return (
                                                <div className='col-lg-2 col-md-12 col-sm-12 border p-1' onClick={() => { Navigate(`/view/blog/category/${res._id}`) }}>
                                                    <div className='row' style={{ cursor: "pointer"}}>
                                                        <div className='col-5 d-flex justify-content-center' >
                                                        <div
                                                            style={{
                                                                borderRadius: "50%",
                                                                width: "40px",
                                                                border: "1px solid #808080",
                                                                height: "40px",
                                                                
                                                            }}
                                                        >
                                                            <img
                                                                src=
                                                                {IMAGE_URL + res.image}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "cover",
                                                                    borderRadius: "50%",
                                                                }}
                                                            />
                                                        </div>
                                                        </div>

                                                        <div className='col-6 pt-2'>
                                                        <span className=' name'>{res.category_name}</span>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                            )
                                        })
                                    }



                                </div>

                                <div className='row  pt-4'>
                                    <h2 className='text-center'>Latest Blogs</h2>
                                </div>



                                <div className='container mt-5 '>
                                    <Carousel interval={3000} pause={false}  className='custom-carousel'>
                                        {blog &&
                                            blog.map((res, index) => {
                                                if (index % sliceSize === 0) {
                                                    const blogSlice = blog.slice(index, index + sliceSize);
                                                    return (
                                                        <Carousel.Item key={index}>
                                                            <div className='row'>
                                                                {blogSlice.map((item, i) => (
                                                                    <div key={i}  className='col-lg-4 col-sm-12'>
                                                                        <div className="card " style={{ width: "100%" }}>
                                                                            <div
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "1px solid #808080",
                                                                                    height: "200px",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src=
                                                                                    {IMAGE_URL + item.banner}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: "100%",
                                                                                        objectFit: "cover",
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div class="card-body d-flex flex-column ">
                                                                                <h6 className="">{item.title}</h6>
                                                                                <div className=' mt-4 d-flex align-items-center justify-content-between'>
                                                                                    <div className='d-flex '>
                                                                                        <div className='d-flex gap-2 '>
                                                                                            <img src={logo} style={{ height: "3vh" }}></img>
                                                                                            <span className='new-txt'>Brainbucks</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='d-flex align-items-center gap-2'>
                                                                                        <MdOutlineCalendarMonth className='txx' style={{ fontSize: "18px" }} />
                                                                                        <span className='txx'>{item.scheduleDateTime}</span>
                                                                                    </div>

                                                                                </div>
                                                                                <button type='button' className='contributor ' onClick={() => { Navigate(`/view/show/blog/${item?._id}`) }}>Read as Somewhere</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Carousel.Item>
                                                    );
                                                }
                                                return null;
                                            })}
                                    </Carousel>

                                </div>

                                <div className='row d-flex justify-content-center  mt-5'>
                                    <div className=' col-6  d-flex justify-content-center align-items-center '>
                                        <div className='p-2' onClick={() => { Navigate(`/view/all/blog/${emptyId}`) }}>
                                            <button type='button' className='btn Blog '>View all Blogs</button>
                                            <GoArrowRight className='arrow' />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    {/* <Footer /> */}
                </div>

            </div>
            <Footer />
        </div>
        // 
    )
}

export default Blog
