import React from 'react';
import Header from '../Header';
import nk_sir from '../assets/nk_sir.jpg';
import sk_sir from '../assets/sk_sir.jpg';
import saurav_sir from '../assets/saurav_sir.jpg';
import ajit_sir from '../assets/ajit_sir.jpg';
import manoj_sir from '../assets/manoj_sir.jpg';
import ami_sir from '../assets/ami_sir.jpg';
import dramveer_sir from '../assets/daramveer.png';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import Footer from '../Footer';

const Team = () => {
    const teamMembers = [
       
        {
            img: nk_sir,
            name: "Nand Yadav",
            title: "Founder & Chairman",
            linkedin: "https://www.linkedin.com/in/nand-k-yadav-28848120/"
        },
        {
            img: sk_sir,
            name: "Santosh Yadav",
            title: "Founder & CEO",
            linkedin: "https://www.linkedin.com/in/santosh-yadav-88b71a12b/"
        },
      
        {
            img: saurav_sir,
            name: "Saurav Kumawat",
            title: "CTO",
            linkedin: "https://www.linkedin.com/in/saurav-kumawat/"
        },
      
        {
            img: ajit_sir,
            name: "Ajit Kumar Singh",
            title: "IAS (83) M.A Society & Polity",
            linkedin: "https://www.linkedin.com/in/ajit-kr-singh-ias-rtd-19385b153/"
        },
        {
            img: manoj_sir,
            name: "Manoj Kumar",
            title: "IAS (03), MBA (IIRM)",
            twitter: "https://twitter.com/iasmanojkumars2"
        },
        {
            img: ami_sir,
            name: "Dr.Amitabh Rajan",
            title: "IAS(79), Public Policy & Governance",
            linkedin: "https://www.linkedin.com/in/dr-amitabh-rajan-ias-a8a26abap/"
        },
       
    ];

    return (
        <div>
            <div style={{ width: "100%", overflow: "hidden", display: "flex", flexDirection: "column" }}>
                {/* Navbar */}
                <div style={{ height: "7vh" }}>
                    <Header />
                </div>

                {/* Main Content */}
                <div className='border' style={{ overflowY: "auto", padding: "20px" }}>
                    <div className='container-fluid'>
                        <div className='row mt-2'>
                            <div className='col-12'>
                                <div className='text-center'>
                                    <h2 style={{ fontWeight: "700" }}>OUR TEAM</h2>
                                    <p className='mt-4' style={{ fontWeight: "600" }}>We have dedicated leaders along with Real IAS/IPS as partners, creators & trainers</p>
                                </div>

                                <div className='row mt-2 d-flex justify-content-evenly'>
                {teamMembers.map((member, index) => (
                    <div key={index} className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mt-4'>
                        <div
                            className='card-body d-flex justify-content-center align-items-center flex-column p-4'
                            style={{ border: "1px solid black", backgroundColor: "#e6eef1", borderRadius: "10px" }}
                        >
                            <div
                                style={{
                                    borderRadius: "50%",
                                    width: "180px",
                                    border: "1px solid #808080",
                                    height: "180px",
                                    overflow: "hidden"
                                }}
                            >
                                <img
                                    src={member.img}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                    alt={`${member.name}'s picture`}
                                />
                            </div>
                            <h4 className='pt-2' style={{ fontWeight: "700" }}>{member.name}</h4>
                            <h6 className='pt-2'>{member.title}</h6>
                            <div className='d-flex mt-3'>
                                {member.linkedin && (
                                    <a href={member.linkedin} className='me-3'>
                                        <FaLinkedin style={{ fontSize: "40px", color: "#0A66C2", cursor: "pointer" }} />
                                    </a>
                                )}
                                {member.twitter && (
                                    <a href={member.twitter}>
                                        <FaTwitter style={{ fontSize: "40px", color: "#1DA1F2", cursor: "pointer" }} />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            <Footer />
        </div>
    );
}

export default Team;
